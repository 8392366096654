import { extractTestCriteriaList, ITestSuite } from './testCriteria';

// Flight test objects and definitions
import { fcuTestKeys } from '../pages/flightTestPage/sections/FcuFlightTestList';
import { livePayloadTestKeys } from '../pages/flightTestPage/sections/LivePayloadFlightTestList';
import { trainerPayloadTestKeys } from '../pages/flightTestPage/sections/TrainerPayloadFlightTestList';
import { vehicleTestKeys } from '../pages/flightTestPage/sections/VehicleFlightTestList';
//import { magTestKeys } from '../pages/magTestPage/MagTestSection';

//import MagTestCriteria from '../pages/magTestPage/magTestCriteria/magTestCriteria.json';

import FcuTestCriteria from '../pages/flightTestPage/flightTestCriteria/fcuFlightTestCriteria.json';
import LivePayloadFlightTestCriteria from '../pages/flightTestPage/flightTestCriteria/livePayloadFlightTestCriteria.json';
import TrainerPayloadFlightTestCriteria from '../pages/flightTestPage/flightTestCriteria/trainerPayloadFlightTestCriteria.json';
import VehicleFlightTestCriteria from '../pages/flightTestPage/flightTestCriteria/vehicleFlightTestCriteria.json';

import serviceFactory from '../services/serviceFactory';
import { testFixtureTestMap } from '../services/testFixture/testFixture.data';
import { PartAssemblyType } from '../services/assembly/IAssemblyService';
import { TestFixtureType } from '../services/testFixture/ITestFixtureService';

function addFlightTestSuite(suiteName: string, testKeys: string[], criteria: any, testSuites: ITestSuite[]): void {
	const testSuite: ITestSuite = {
		testSuiteName: suiteName,
		tests: []
	};
	console.log(criteria);

	for (const testKey of testKeys) {
		console.log(testKey);
		// convert the testKey to pascalCase
		const testKeyPascal = testKey.charAt(0).toUpperCase() + testKey.slice(1);
		const trainerPayloadTestCriteriaList = extractTestCriteriaList(criteria, testKeyPascal);
		// find the item in the criteria array that testName matches the testKey
		//const testCriteria = criteria.testSteps.find((item) => item.testName == testKey);
		//const testCriteria = criteria.testSteps[testKey];
		console.log(trainerPayloadTestCriteriaList);
		testSuite.tests.push({
			testName: testKey,
			testCriteria: trainerPayloadTestCriteriaList,
			benchTest: false
		});
	}
	testSuites.push(testSuite);
}

export async function generateTestSuitesList(): Promise<ITestSuite[]> {
	const testSuites: ITestSuite[] = [];
	//const testFixtureService = serviceFactory.get('TestFixtureService');
	const assemblyService = serviceFactory.get('AssemblyService');
	const testService = serviceFactory.get('TestFixtureService');

	// Load all flight test suites and variants from the test definition objects
	// load each flight test type, and by the test keys for that type, lookup the requirements from the test definition objects
	addFlightTestSuite('FCU Flight Test', fcuTestKeys, FcuTestCriteria, testSuites);
	addFlightTestSuite('Live Payload Flight Test', livePayloadTestKeys, LivePayloadFlightTestCriteria, testSuites);
	addFlightTestSuite(
		'Trainer Payload Flight Test',
		trainerPayloadTestKeys,
		TrainerPayloadFlightTestCriteria,
		testSuites
	);
	addFlightTestSuite('Vehicle Flight Test', vehicleTestKeys, VehicleFlightTestCriteria, testSuites);

	// load magnetometer test suites and variants from the test definition objects

	// Load all bench test suites and variants from testFixtureService and attached shim.

	// for all of the test fixtures and test types generate a test suite, and for each test, attempt to call that test on the shim with -s to obtain the test criteria from the results.
	// then store the test criteria master object in global storage as a a cache, so we don't have to do that step again.
	for (const assemblyType in testFixtureTestMap) {
		// console.log(assemblyType);
		for (const testFixture in testFixtureTestMap[assemblyType as PartAssemblyType]) {
			// console.log(testFixture);
			if (!testService.isTestFixtureType(testFixture)) {
				continue;
			}

			const testDetails = testFixtureTestMap[assemblyType as PartAssemblyType][testFixture as TestFixtureType];

			if (!testDetails) {
				continue;
			}

			const testSuite: ITestSuite = {
				testSuiteName: `${assemblyService.getLabelFromPartType(assemblyType)} - ${testDetails.name}`,
				fixtureInfo: testDetails,
				tests: []
			};
			//const testCriteria = await testFixtureService.getTestCriteria(test);
			for (const test of testDetails.tests) {
				// console.log(test);
				// TODO: source the test criteria from the shim!
				// call the python script of that particular test against the shim, harvest it's success/failure nuggets, and then store the test criteria portion
				// can i access the global list here and edit it?
				// Skip tests for criteria reports that are marked as dev only, they aren't official yet.
				if (test.devOnly !== undefined && test.devOnly) {
					continue;
				}
				testSuite.tests.push({
					testName: test.testName,
					testCriteria: [],
					benchTest: true,
					pythonTestInfo: test
				});
			}
			testSuites.push(testSuite);
		}
	}
	// Let's return the test suites, and then call them in the react object.
	// console.log(testSuites);
	return testSuites;
}
