import { Page } from '@redskytech/framework/996';
import { Box, RsFormControl, RsFormGroup } from '@redskytech/framework/ui';
import { DateUtils } from '@redskytech/framework/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTableFilterMeta, DataTableFilterMetaData, DataTableOperatorFilterMetaData } from 'primereact/datatable';
import * as React from 'react';
import { useState } from 'react';
import ColumnHeader from '../../components/listDataTable/columnHeader/ColumnHeader';
import ListDataTable from '../../components/listDataTable/ListDataTable';
import TableSearchHeader from '../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import PageHeader from '../../components/pageHeader/PageHeader';
import StatusChip from '../../components/statusChip/StatusChip';
import useDataTableFilters from '../../customHooks/useDataTableFilters';
import { ApiRequestV1 } from '../../generated/apiRequests';
import router from '../../utils/router';
import './KitListPage.scss';

const FieldKeys = {
	KIT_NUMBER: 'kitNumber',
	CREATED_ON: 'createdOn',
	MODIFIED_ON: 'modifiedOn',
	COMPLETED: 'completed'
};

const KitListPage: React.FC = () => {
	const [kitRecords, setKitRecords] = useState<RedSky.RsPagedResponseData<Api.V1.Kit.Paged.Get.Res[]>>({
		data: [],
		total: 0
	});

	const queryParams = router.getQueryParams<{
		sortField: string;
		sortOrder: string;
		globalSearch: string;
		page: number;
		perPage: number;
		filter: string;
	}>([
		{
			key: 'sortField',
			default: FieldKeys.CREATED_ON,
			type: 'string'
		},
		{
			key: 'sortOrder',
			default: 'DESC',
			type: 'string'
		},
		{
			key: 'page',
			default: 1,
			type: 'integer'
		},
		{
			key: 'perPage',
			default: 25,
			type: 'integer'
		},
		{
			key: 'filter',
			default: '',
			type: 'string'
		}
	]);

	const initialFiltersFormGroup: RsFormGroup = new RsFormGroup([
		new RsFormControl<string>(FieldKeys.KIT_NUMBER, ''),
		new RsFormControl<string>(FieldKeys.CREATED_ON, ''),
		new RsFormControl<string>(FieldKeys.MODIFIED_ON, ''),
		new RsFormControl<string>(FieldKeys.COMPLETED, '')
	]);
	const initialFilters: DataTableFilterMeta = {
		[FieldKeys.KIT_NUMBER]: {
			operator: FilterOperator.AND,
			constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.CREATED_ON]: {
			operator: FilterOperator.AND,
			constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.MODIFIED_ON]: {
			operator: FilterOperator.AND,
			constraints: [{ value: '', matchMode: FilterMatchMode.EQUALS }]
		},
		[FieldKeys.COMPLETED]: {
			operator: FilterOperator.AND,
			constraints: [
				{
					value: '',
					matchMode: FilterMatchMode.CONTAINS
				}
			]
		}
	};
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { filters, globalSearch, setGlobalSearch } = useDataTableFilters(
		initialFilters,
		initialFiltersFormGroup,
		getUpdatedFilters,
		''
	);

	async function getData(pageQuery: RedSky.PageQuery) {
		const { page = 1, perPage = 10, sortBy, sortOrder = 'NONE', filter } = pageQuery;
		setIsLoading(true);
		const filterValues: string[] = [];
		for (const i in filters) {
			if (Object.prototype.hasOwnProperty.call(filters[i], 'constraints')) {
				(filters[i] as DataTableOperatorFilterMetaData).constraints.forEach(
					(constraint: DataTableFilterMetaData) => {
						if (constraint.value !== null) filterValues.push(`${i}_${constraint.value}`);
					}
				);
			}
		}
		router.updateQueryParams({
			sortField: pageQuery.sortBy || '',
			sortOrder: pageQuery.sortOrder || -1,
			globalSearch,
			page: pageQuery.page || 1,
			perPage: pageQuery.perPage || 50,
			...(filterValues.length && { filter: filterValues.join(',') })
		});

		try {
			const data = await ApiRequestV1.getKitPaged({
				page,
				perPage,
				sortOrder,
				...(sortBy && { sortBy }),
				...(filter && { filter })
			});
			setKitRecords(data);
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	}

	function handleRowClick(rowData: any) {
		router.navigate(`/kit/checkout?kn=${rowData.data.kitNumber}`);
	}

	function getUpdatedFilters(field: string, value: any) {
		const filter: any = filters[field];

		const constraint = [
			{
				value: value,
				matchMode: filter?.constraints[0]?.matchMode
			}
		];
		if (field === FieldKeys.KIT_NUMBER) {
			return {
				...filters,
				[field]: {
					...filter,
					constraints: constraint
				}
			};
		}
		return {
			...filters,
			[field]: {
				...filter,
				constraints: constraint
			}
		};
	}

	return (
		<Page className={'rsKitListPage'}>
			<PageHeader title={'Kit Records'} />
			<Box className={'pageContent'}>
				<ListDataTable
					loading={isLoading}
					onRowClick={handleRowClick}
					data={kitRecords}
					getData={getData}
					globalFilter={globalSearch}
					globalFilterFields={[FieldKeys.KIT_NUMBER]}
					onClearAllFilters={() => {}}
					onFilterClear={() => {}}
					onFilterApply={() => {
						console.log('filter apply');
					}}
					filters={filters}
					rowsPerPageOptions={[10, 25, 50, 100]}
					initialRowsPerPage={queryParams.perPage}
					first={(queryParams.page - 1) * queryParams.perPage}
					sortField={queryParams.sortField}
					sortOrder={queryParams.sortOrder === 'ASC' ? 1 : -1}
					header={
						<TableSearchHeader
							searchValue={globalSearch}
							onChange={(value: string) => setGlobalSearch(value)}
							placeholder={'Search Kits'}
							title={''}
						/>
					}
				>
					<Column
						field={FieldKeys.KIT_NUMBER}
						sortField={FieldKeys.KIT_NUMBER}
						header={<ColumnHeader label={'Kit #'} />}
						body={(part: Api.V1.Kit.Paged.Get.Res) => part.kitNumber}
					/>
					<Column
						field={FieldKeys.CREATED_ON}
						sortField={FieldKeys.CREATED_ON}
						header={<ColumnHeader label={'Created On'} />}
						body={(part: Api.V1.Kit.Paged.Get.Res) => DateUtils.displayDate(part.createdOn)}
						sortable
					/>
					<Column
						field={FieldKeys.MODIFIED_ON}
						sortField={FieldKeys.MODIFIED_ON}
						header={<ColumnHeader label={'Modified On'} />}
						body={(part: Api.V1.Kit.Paged.Get.Res) => DateUtils.displayDate(part.modifiedOn)}
						sortable
					/>
					<Column
						field={FieldKeys.COMPLETED}
						sortField={FieldKeys.COMPLETED}
						header={<ColumnHeader label={'Completed'} />}
						body={(part: Api.V1.Kit.Paged.Get.Res) => (
							<StatusChip status={part.completed ? 'COMPLETE' : 'PENDING'} />
						)}
						sortable
					/>
				</ListDataTable>
			</Box>
		</Page>
	);
};

export default KitListPage;
