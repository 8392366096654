import {
	atom,
	RecoilState,
	useRecoilTransactionObserver_UNSTABLE,
	useRecoilCallback,
	RecoilValue,
	Loadable,
	Snapshot
} from 'recoil';
import * as React from 'react';
import { ObjectUtils } from '../utils/utils.js';
import { AuthTokens } from '../services/user/UserService';
import { TesterShimStatusData } from '../services/socketio/SocketioService';
import { TestFixtureStatus, TestPrompt, TestResult } from '../services/testFixture/ITestFixtureService';
import { InspectionResult } from '../services/kit/IKitService';
import { CalibrationOutput, FlightFileData, SlotResultStatus } from '../services/calibration/ICalibrationService';
import { TestSettings } from '../pages/settingsPage/SettingsPage';
import { FirmwareSettings } from '../components/firmwareList/FirmwareList';

enum GlobalStateKeys {
	AUTH_TOKENS = 'AuthTokens',
	USER = 'User',
	FIRMWARE_DOWNLOAD_STATE = 'FirmwareDownloadStatus',
	FIRMWARE_SETTINGS = 'FirmwareSettings',
	FIRMWARE_DELETE_STATE = 'FirmwareDeleteStatus',
	FIRMWARE_ACTIVATE_STATE = 'FirmwareActivateStatus',
	TESTER_SHIM_STATUS = 'TesterShimStatus',
	TEST_FIXTURE_STATUS = 'TestFixtureStatus',
	TEST_RESULTS = 'TestResults',
	INSPECTION_RESULTS = 'InspectionResults',
	TEST_PROMPTS = 'TestPrompts',
	RTSP_VIDEO_URL = 'RtspVideoUrl',
	IS_CALIBRATION_CONNECTED = 'IsCalibrationConnected',
	CALIBRATION_OUTPUT = 'CalibrationOutput',
	SLOT_RESULT_STATUSES = 'SlotResultStatuses',
	TERMINATED_TEST_CODE = 'TerminatedTestCode',
	UPLOADED_FILE_DATA = 'UploadedFileData',
	FLIGHT_TEST_FILE_DATA = 'FlightTestFileData',
	TEST_SETTINGS = 'TestSettings'
}

// Change based on project soo we don't have classing when developing on localhost (va = Volcanic Admin)
const KEY_PREFIX = 'flir-';

class GlobalState {
	authTokens: RecoilState<AuthTokens | undefined>;
	user: RecoilState<Api.V1.User.Me.Get.Res | undefined>;
	testerShimStatus: RecoilState<TesterShimStatusData>;
	testFixtureStatus: RecoilState<TestFixtureStatus>;
	testResults: RecoilState<TestResult[]>;
	inspectionResults: RecoilState<InspectionResult[]>;
	testPrompts: RecoilState<TestPrompt[]>;
	rtspVideoUrl: RecoilState<{ url: string; xRes: number; yRes: number } | undefined>;
	isCalibrationConnected: RecoilState<boolean>;
	calibrationOutput: RecoilState<CalibrationOutput[]>;
	slotResultStatuses: RecoilState<SlotResultStatus[]>;
	terminatedTestCode: RecoilState<1 | 2 | undefined>;
	uploadedFileData: RecoilState<
		{ partId: number; tray: number; position: number; fileUrl: string; fileSize: string }[]
	>;
	flightTestFileData: RecoilState<FlightFileData[] | undefined>;
	testSettings: RecoilState<TestSettings>;
	firmwareSettings: RecoilState<FirmwareSettings>;
	firmwareDownloadState: RecoilState<'INPROGRESS' | 'DONE'>;
	firmwareDeleteState: RecoilState<'INPROGRESS' | 'DONE'>;
	firmwareActivateState: RecoilState<'INPROGRESS' | 'DONE'>;

	private savedDefaultFunctions: Map<RecoilState<any>, () => any> = new Map();
	private localStoredRecoilKeys: Set<string> = new Set();

	constructor() {
		this.authTokens = this.createRecoilState<AuthTokens | undefined>(GlobalStateKeys.AUTH_TOKENS, undefined, true);
		this.user = this.createRecoilState<Api.V1.User.Me.Get.Res | undefined>(GlobalStateKeys.USER, undefined, false);
		this.testerShimStatus = this.createRecoilState<TesterShimStatusData>(
			GlobalStateKeys.TESTER_SHIM_STATUS,
			{
				packetsPerSecond: 0,
				status: 'DISCONNECTED',
				version: ''
			},
			false
		);
		this.testFixtureStatus = this.createRecoilState<TestFixtureStatus>(
			GlobalStateKeys.TEST_FIXTURE_STATUS,
			{
				currentTestFixtureName: null,
				currentTestIndex: 0,
				testList: []
			},
			false
		);
		this.testResults = this.createRecoilState<TestResult[]>(GlobalStateKeys.TEST_RESULTS, [], false);
		this.inspectionResults = this.createRecoilState<InspectionResult[]>(
			GlobalStateKeys.INSPECTION_RESULTS,
			[],
			false
		);
		this.testPrompts = this.createRecoilState<TestPrompt[]>(GlobalStateKeys.TEST_PROMPTS, [], false);
		this.rtspVideoUrl = this.createRecoilState<{ url: string; xRes: number; yRes: number } | undefined>(
			GlobalStateKeys.RTSP_VIDEO_URL,
			undefined,
			false
		);
		this.isCalibrationConnected = this.createRecoilState<boolean>(
			GlobalStateKeys.IS_CALIBRATION_CONNECTED,
			false,
			false
		);
		this.calibrationOutput = this.createRecoilState<CalibrationOutput[]>(
			GlobalStateKeys.CALIBRATION_OUTPUT,
			[],
			false
		);
		this.slotResultStatuses = this.createRecoilState<SlotResultStatus[]>(
			GlobalStateKeys.SLOT_RESULT_STATUSES,
			[],
			false
		);
		this.terminatedTestCode = this.createRecoilState<1 | 2 | undefined>(
			GlobalStateKeys.TERMINATED_TEST_CODE,
			undefined,
			false
		);
		this.uploadedFileData = this.createRecoilState<
			{ partId: number; tray: number; position: number; fileUrl: string; fileSize: string }[]
		>(GlobalStateKeys.UPLOADED_FILE_DATA, [], false);
		this.flightTestFileData = this.createRecoilState<FlightFileData[] | undefined>(
			GlobalStateKeys.FLIGHT_TEST_FILE_DATA,
			undefined,
			false
		);
		this.testSettings = this.createRecoilState<TestSettings>(
			GlobalStateKeys.TEST_SETTINGS,
			{
				radioMhz: '2475',
				vtoId: '',
				vtoSerial: '',
				batteryId: '',
				batterySerial: '',
				hobId: '',
				hobSerial: '',
				orientId: '',
				orientSerial: ''
			},
			true
		);
		this.firmwareSettings = this.createRecoilState<FirmwareSettings>(
			GlobalStateKeys.FIRMWARE_SETTINGS,
			{
				commercialFirmware: '',
				militaryFirmware: ''
			},
			true
		);
		this.firmwareDownloadState = this.createRecoilState<'INPROGRESS' | 'DONE'>(
			GlobalStateKeys.FIRMWARE_DOWNLOAD_STATE,
			'DONE',
			false
		);
		this.firmwareDeleteState = this.createRecoilState<'INPROGRESS' | 'DONE'>(
			GlobalStateKeys.FIRMWARE_DELETE_STATE,
			'DONE',
			false
		);
		this.firmwareActivateState = this.createRecoilState<'INPROGRESS' | 'DONE'>(
			GlobalStateKeys.FIRMWARE_ACTIVATE_STATE,
			'DONE',
			false
		);
	}

	reloadDefaults() {
		this.savedDefaultFunctions.forEach((defaultFunc, recoilState) => {
			setRecoilExternalValue(recoilState, typeof defaultFunc === 'function' ? defaultFunc() : defaultFunc);
		});
	}

	handleGlobalStateChange(snapshot: Snapshot) {
		for (const item of snapshot.getNodes_UNSTABLE({ isModified: true })) {
			let value = snapshot.getLoadable(item).contents as string;
			const keysToNotLog = [KEY_PREFIX + GlobalStateKeys.TESTER_SHIM_STATUS];
			if (process.env.NODE_ENV === 'development' && !keysToNotLog.includes(item.key as GlobalStateKeys)) {
				console.log('Recoil item changed: ', item.key);
				console.log('Value: ', value);
			}

			if (this.localStoredRecoilKeys.has(item.key)) {
				if (typeof value === 'object') value = JSON.stringify(value);
				localStorage.setItem(item.key, value);
			}
		}
	}

	private createRecoilState<T>(key: GlobalStateKeys, defaultValue: T, localStorage: boolean): RecoilState<T> {
		const recoilState = atom<T>({
			key: KEY_PREFIX + key,
			default: localStorage
				? loadFromLocalStorage(key, ObjectUtils.clone(defaultValue))
				: ObjectUtils.clone(defaultValue)
		});
		if (localStorage) {
			this.localStoredRecoilKeys.add(recoilState.key);
		}
		this.savedDefaultFunctions.set(
			recoilState,
			localStorage
				? () => loadFromLocalStorage(key, ObjectUtils.clone(defaultValue))
				: () => ObjectUtils.clone(defaultValue)
		);
		return recoilState;
	}
}

function loadFromLocalStorage<T>(key: GlobalStateKeys, defaultValue: T): T {
	const itemString: string | null = localStorage.getItem(KEY_PREFIX + key);
	if (!itemString) return defaultValue;
	let item: T | null;
	try {
		item = JSON.parse(itemString);
	} catch (e) {
		// primitives aren't going to parse as a JSON.
		// but regardless of the failure, we'll return what was originally set
		item = itemString as unknown as T;
	}
	if (typeof item === 'string' && (item === 'undefined' || item === 'null')) return defaultValue;
	if (!item) return defaultValue;

	return item;
}

export function clearPersistentState() {
	localStorage.removeItem(KEY_PREFIX + GlobalStateKeys.AUTH_TOKENS);
	globalState.reloadDefaults();
}

export const GlobalStateObserver: React.FC = () => {
	useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
		globalState.handleGlobalStateChange(snapshot);
	});
	return null;
};

const globalState = new GlobalState();
export default globalState;

/**
 * Returns a Recoil state value, from anywhere in the app.
 *
 * Can be used outside the React tree (outside a React component), such as in utility scripts, etc.

 * <GlobalStateInfluencer> must have been previously loaded in the React tree, or it won't work.
 * Initialized as a dummy function "() => null", it's reference is updated to a proper Recoil state mutator when GlobalStateInfluencer is loaded.
 *
 * @example const lastCreatedUser = getRecoilExternalValue(lastCreatedUserState);
 *
 */
export let getRecoilExternalLoadable: <T>(recoilValue: RecoilValue<T>) => Loadable<T> = () => null as any;

/**
 * Retrieves the value from the loadable. More information about loadables are here:
 * https://recoiljs.org/docs/api-reference/core/Loadable
 * @param recoilValue Recoil value to retrieve its base value
 */
export function getRecoilExternalValue<T>(recoilValue: RecoilValue<T>): T {
	return getRecoilExternalLoadable<T>(recoilValue).getValue();
}

/**
 * Sets a Recoil state value, from anywhere in the app.
 *
 * Can be used outside the React tree (outside a React component), such as in utility scripts, etc.
 *
 * <RecoilExternalStatePortal> must have been previously loaded in the React tree, or it won't work.
 * Initialized as a dummy function "() => null", it's reference is updated to a proper Recoil state mutator when GlobalStateInfluencer is loaded.
 *
 * NOTE - Recoil value isn't fully changed until some time later.
 *
 * @example setRecoilExternalState(lastCreatedUserState, newUser)
 */
export let setRecoilExternalValue: <T>(
	recoilState: RecoilState<T>,
	valOrUpdater: ((currVal: T) => T) | T
) => void = () => null as any;

export const GlobalStateInfluencer: React.FC = () => {
	useRecoilCallback(({ set, snapshot }) => {
		setRecoilExternalValue = set;
		getRecoilExternalLoadable = snapshot.getLoadable;
		return async () => {};
	})();

	// We need to update the getRecoilExternalLoadable every time there's a new snapshot
	// Otherwise we will load old values from when the component was mounted
	useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
		getRecoilExternalLoadable = snapshot.getLoadable;
	});

	return null;
};
