import { Page } from '@redskytech/framework/996';
import {
	Box,
	Button,
	InputText,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify
} from '@redskytech/framework/ui';
import classNames from 'classnames';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTableFilterMeta, DataTableRowClickEventParams } from 'primereact/datatable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import AssemblyAuditCheck from '../../components/assemblyAuditCheck/AssemblyAuditCheck';
import ColumnHeader from '../../components/listDataTable/columnHeader/ColumnHeader';
import ListDataTable from '../../components/listDataTable/ListDataTable';
import TableSearchHeader from '../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import PageHeader from '../../components/pageHeader/PageHeader';
import TestAuditCheck from '../../components/testAuditCheck/TestAuditCheck';
import useDataTableFilters from '../../customHooks/useDataTableFilters';
import useIsMounted from '../../customHooks/useIsMounted';
import { ApiRequestV1 } from '../../generated/apiRequests';
import ConfirmPopup, { ConfirmPopupProps } from '../../popups/confirmPopup/ConfirmPopup';
import FamilyTreePopup, { FamilyTreePopupProps } from '../../popups/familyTreePopup/FamilyTreePopup';
import { ngAllowedPartNumbers } from '../../services/assembly/assembly.data';
import globalState from '../../state/globalState';
import router from '../../utils/router';
import { DateUtils, WebUtils } from '../../utils/utils';
import ActivityLogPaper from './activityLogPaper/ActivityLogPaper';
import './AssemblyDetailsPage.scss';
import AssemblyInformationPaper from './assemblyInformationPaper/AssemblyInformationPaper';
import AssemblyNotesPaper from './assemblyNotesPaper/AssemblyNotesPaper';
import SubAssemblyNotesPaper from './subAssemblyNotesPaper/SubAssemblyNotesPaper';
import TestResultsTable from './testResultsTable/TestResultsTable';
import TestSummaryTable from './testSummaryTable/TestSummaryTable';
import { AssemblyTreeTestAuditResult, processPartFamilyTreeAudit } from '../../utils/generateAuditList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AssemblyDetailsPageProps {}

const FieldKeys = {
	NAME: 'part.name',
	SERIAL_NUMBER: 'part.serialNumber',
	PART_NUMBER: 'part.partNumber',
	HARDWARE_REV: 'part.hardwareRev',
	ASSOCIATED_ON: 'part.associatedOn',
	ASSOCIATED_FIRST_NAME: 'user.firstName',
	ASSOCIATED_LAST_NAME: 'user.lastName'
};

const AssemblyDetailsPage: React.FC<AssemblyDetailsPageProps> = () => {
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const { partId } = router.getQueryParams<{ partId: number }>([
		{
			key: 'pi',
			default: 0,
			type: 'integer',
			alias: 'partId'
		}
	]);

	const [kitNumber, setKitNumber] = useState<Api.V1.Kit.Get.Res['kitNumber']>('');
	const [inspectionChecklists, setInspectionChecklists] = useState<Api.V1.Inspection.By.PartId.Get.Res[]>([]);
	const [assemblyDetails, setAssemblyDetails] = useState<Api.V1.Part.Get.Res>();
	const [actionDetails, setActionDetails] = useState<Api.V1.ActionLog.Paged.Get.Res[]>();
	const [subAssemblyDetails, setSubAssemblyDetails] = useState<
		RedSky.RsPagedResponseData<Api.V1.PartMap.Children.Paged.Get.Res[]>
	>({
		data: [],
		total: 0
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();
	const [currentPageQuery, setCurrentPageQuery] = useState<RedSky.PageQuery>({
		page: 1,
		perPage: 100
	});
	const [testAuditData, setTestAuditData] = React.useState<AssemblyTreeTestAuditResult | undefined>(undefined);

	const initialFiltersFormGroup: RsFormGroup = new RsFormGroup([
		new RsFormControl<string>(FieldKeys.NAME, ''),
		new RsFormControl<string>(FieldKeys.SERIAL_NUMBER, ''),
		new RsFormControl<string>(FieldKeys.PART_NUMBER, ''),
		new RsFormControl<string>(FieldKeys.HARDWARE_REV, ''),
		new RsFormControl<string>(FieldKeys.ASSOCIATED_ON, ''),
		new RsFormControl<string>(FieldKeys.ASSOCIATED_FIRST_NAME, '')
	]);
	const initialFilters: DataTableFilterMeta = {
		[FieldKeys.NAME]: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.SERIAL_NUMBER]: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.PART_NUMBER]: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.HARDWARE_REV]: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.ASSOCIATED_ON]: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.ASSOCIATED_FIRST_NAME]: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.ASSOCIATED_LAST_NAME]: {
			operator: FilterOperator.OR,
			constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
		}
	};
	const {
		filters,
		activeFilters,
		globalSearch,
		setGlobalSearch,
		handleClearAllFilters,
		handleFilterClear,
		handleFilterApply,
		handleUpdateControl,
		filtersFormGroup
	} = useDataTableFilters(initialFilters, initialFiltersFormGroup, getUpdatedFilters);
	const isMounted = useIsMounted();

	useEffect(() => {
		handleUpdateData();
	}, [partId]);

	useEffect(() => {
		if (partId < 0) return;
		setTestAuditData(undefined);
		(async () => {
			const partAudit = await processPartFamilyTreeAudit(partId);
			console.log('getting part audit information');
			console.log(partAudit);
			if (!partAudit) {
				return;
			}
			setTestAuditData(partAudit);
		})();
	}, [partId]);

	async function getAssemblyDetails() {
		try {
			if (!partId) return;

			const partData = await ApiRequestV1.getPart({ partId });

			try {
				const kitNumber = await ApiRequestV1.getKitByPartId({ partId });
				setKitNumber(kitNumber.kitNumber);
			} catch (e) {
				//silently fail;
			}

			try {
				const inspections = await ApiRequestV1.getInspectionByPartId({ partId });
				setInspectionChecklists(inspections);
			} catch (e) {
				//silently fail;
			}

			setAssemblyDetails(partData);
			const logRes = await ApiRequestV1.getActionLogPaged({
				referenceType: 'PART',
				referenceId: partId,
				page: 1,
				perPage: 5,
				sortBy: 'actionLog.id', // We can't use createdAt because lots of actions can happen at the same time
				sortOrder: 'DESC'
			});
			setActionDetails(logRes.data);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		setCurrentPageQuery(pageQuery);
		try {
			if (!isMounted) return;
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;
			pageQuery.perPage = 100;
			const res = await ApiRequestV1.getPartMapChildrenPaged({ ...pageQuery, parentId: partId });
			setSubAssemblyDetails(res);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
		setIsLoading(false);
	}

	function getUpdatedFilters(field: string, value: any) {
		const filter: any = filters[field];
		const constraint = [
			{
				value: value,
				matchMode: filter?.constraints[0]?.matchMode
			}
		];
		if (field === FieldKeys.ASSOCIATED_FIRST_NAME) {
			return {
				...filters,
				[field]: {
					...filter,
					constraints: constraint
				},
				[FieldKeys.ASSOCIATED_LAST_NAME]: {
					operator: FilterOperator.OR,
					constraints: constraint
				}
			};
		}
		return {
			...filters,
			[field]: {
				...filter,
				constraints: constraint
			}
		};
	}

	async function getFamilyTreeData(): Promise<CustomTypes.PartFamilyTreeResponse['familyTree'] | null> {
		try {
			const { familyTree } = await ApiRequestV1.getPartFamilyTree({ partId });
			return familyTree;
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'));
		}
		return null;
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		// if user role is ngTechnician, just return and don't provide an option to havigate down to a child.
		if (user?.role === 'ngTechnician') return;
		router.navigate('/assembly/details?pi=' + data.partId).catch(console.error);
	}

	function handleUpdateData() {
		getData(currentPageQuery).catch(console.error);
		getAssemblyDetails().catch(console.error);
	}

	function handleDisassociate(childId: number, parentId: number) {
		popupController.open<ConfirmPopupProps>(ConfirmPopup, {
			title: 'Disassociate?',
			message: 'Are you sure you want to disassociate this subassembly from its parent assembly?',
			onConfirm: async () => {
				try {
					await ApiRequestV1.deletePartMap({ childId, parentId });
					handleUpdateData();
					rsToastify.success('Subassembly was successfully disassociated.', 'Subassembly Disassociated');
				} catch (e) {
					rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
				}
			}
		});
	}

	function getPageHeaderTitle() {
		const hasParent = !!assemblyDetails?.parent.length;
		const hasChildren = !!assemblyDetails?.children.length;
		if (hasParent && hasChildren) {
			return 'Subassembly';
		}
		if (hasParent && !hasChildren) {
			return 'Component';
		}
		return 'Assembly';
	}

	return (
		<Page className={'rsAssemblyDetailsPage'}>
			<PageHeader
				title={`${getPageHeaderTitle()} Details`}
				onBack={() => {
					router.back('/assembly/list');
				}}
				rightNode={
					<Button
						look={'containedPrimary'}
						onClick={async () => {
							const familyTreeData = await getFamilyTreeData();
							popupController.open<FamilyTreePopupProps>(FamilyTreePopup, {
								familyTreeData,
								currentViewedItemId: partId
							});
						}}
					>
						{getPageHeaderTitle()} Family Tree
					</Button>
				}
			/>
			<Box className={'content'}>
				<Box display={'flex'} gap={32}>
					{assemblyDetails && (
						<AssemblyInformationPaper
							assemblyDetails={assemblyDetails}
							kitNumber={kitNumber}
							checklistEntries={inspectionChecklists}
						/>
					)}
					{actionDetails && <ActivityLogPaper actionLogs={actionDetails} />}
				</Box>
				{assemblyDetails && testAuditData ? (
					<AssemblyAuditCheck mode={'NOTICE'} testAuditData={testAuditData} partId={partId} />
				) : (
					<div></div>
				)}
				{assemblyDetails && testAuditData ? (
					<TestAuditCheck mode={'NOTICE'} testAuditData={testAuditData} partId={partId} />
				) : (
					<div></div>
				)}
				{!!assemblyDetails?.children.length && (
					<ListDataTable
						className={'subassemblyTable'}
						loading={isLoading}
						onClearAllFilters={handleClearAllFilters}
						onFilterClear={handleFilterClear}
						onFilterApply={handleFilterApply}
						filters={filters}
						data={subAssemblyDetails}
						getData={getData}
						globalFilter={globalSearch}
						globalFilterFields={[
							FieldKeys.NAME,
							FieldKeys.HARDWARE_REV,
							FieldKeys.PART_NUMBER,
							FieldKeys.SERIAL_NUMBER,
							FieldKeys.ASSOCIATED_LAST_NAME,
							FieldKeys.ASSOCIATED_FIRST_NAME
						]}
						rows={100}
						scrollHeight={'400px'}
						onRowClick={handleRowClick}
						header={
							<TableSearchHeader
								searchValue={globalSearch}
								onChange={(value: string) => setGlobalSearch(value)}
								placeholder={'Search subassemblies'}
								title={'Subassemblies'}
							/>
						}
					>
						<Column
							className={classNames({ activeFilter: activeFilters.includes(FieldKeys.NAME) })}
							field={FieldKeys.NAME}
							sortField={FieldKeys.NAME}
							header={<ColumnHeader label={'Name'} isActiveSort={activeSortColumn === FieldKeys.NAME} />}
							sortable
							filter
							filterElement={
								<InputText
									inputMode={'text'}
									placeholder={'Name'}
									control={filtersFormGroup.get(FieldKeys.NAME)}
									updateControl={handleUpdateControl}
								/>
							}
							body={(part: Api.V1.PartMap.Children.Paged.Get.Res) => part.partName}
						/>
						<Column
							className={classNames({
								activeFilter: activeFilters.includes(FieldKeys.SERIAL_NUMBER)
							})}
							field={FieldKeys.SERIAL_NUMBER}
							sortField={FieldKeys.SERIAL_NUMBER}
							header={
								<ColumnHeader
									label={'Serial #'}
									isActiveSort={activeSortColumn === FieldKeys.SERIAL_NUMBER}
								/>
							}
							sortable
							filter
							filterElement={
								<InputText
									inputMode={'text'}
									placeholder={'Serial #'}
									control={filtersFormGroup.get(FieldKeys.SERIAL_NUMBER)}
									updateControl={handleUpdateControl}
								/>
							}
							body={(part: Api.V1.PartMap.Children.Paged.Get.Res) => part.partSerialNumber}
						/>
						<Column
							className={classNames({ activeFilter: activeFilters.includes(FieldKeys.PART_NUMBER) })}
							field={FieldKeys.PART_NUMBER}
							sortField={FieldKeys.PART_NUMBER}
							header={
								<ColumnHeader
									label={'Part #'}
									isActiveSort={activeSortColumn === FieldKeys.PART_NUMBER}
								/>
							}
							sortable
							filter
							filterElement={
								<InputText
									inputMode={'text'}
									placeholder={'Part #'}
									control={filtersFormGroup.get(FieldKeys.PART_NUMBER)}
									updateControl={handleUpdateControl}
								/>
							}
							body={(part: Api.V1.PartMap.Children.Paged.Get.Res) => part.partPartNumber}
						/>
						<Column
							className={classNames({ activeFilter: activeFilters.includes(FieldKeys.HARDWARE_REV) })}
							field={FieldKeys.HARDWARE_REV}
							sortField={FieldKeys.HARDWARE_REV}
							header={
								<ColumnHeader
									label={'Hardware Rev'}
									isActiveSort={activeSortColumn === FieldKeys.HARDWARE_REV}
								/>
							}
							sortable
							filter
							filterElement={
								<InputText
									inputMode={'text'}
									placeholder={'Hardware Rev'}
									control={filtersFormGroup.get(FieldKeys.HARDWARE_REV)}
									updateControl={handleUpdateControl}
								/>
							}
							body={(part: Api.V1.PartMap.Children.Paged.Get.Res) => part.partHardwareRev}
						/>
						<Column
							className={classNames({
								activeFilter: activeFilters.includes(FieldKeys.ASSOCIATED_ON)
							})}
							field={FieldKeys.ASSOCIATED_ON}
							sortField={FieldKeys.ASSOCIATED_ON}
							header={
								<ColumnHeader
									label={'Associated On'}
									isActiveSort={activeSortColumn === FieldKeys.ASSOCIATED_ON}
								/>
							}
							sortable
							filter
							filterElement={
								<InputText
									inputMode={'text'}
									type={'date'}
									control={filtersFormGroup.get(FieldKeys.ASSOCIATED_ON)}
									updateControl={(control) => {
										control.value = DateUtils.convertInputDateToServerDate(
											control.value.toString()
										);
										handleUpdateControl(control);
									}}
								/>
							}
							body={(part: Api.V1.PartMap.Children.Paged.Get.Res) =>
								part.partAssociatedOn ? DateUtils.displayDate(part.partAssociatedOn) : ''
							}
						/>
						<Column
							className={classNames({
								activeFilter: activeFilters.includes(FieldKeys.ASSOCIATED_FIRST_NAME)
							})}
							field={FieldKeys.ASSOCIATED_FIRST_NAME}
							sortField={FieldKeys.ASSOCIATED_FIRST_NAME}
							header={
								<ColumnHeader
									label={'Associated By'}
									isActiveSort={activeSortColumn === FieldKeys.ASSOCIATED_FIRST_NAME}
								/>
							}
							sortable
							filter
							filterElement={
								<InputText
									inputMode={'text'}
									placeholder={'Associated By'}
									control={filtersFormGroup.get(FieldKeys.ASSOCIATED_FIRST_NAME)}
									updateControl={handleUpdateControl}
								/>
							}
							body={(part: Api.V1.PartMap.Children.Paged.Get.Res) =>
								`${part.associatedBy[0]?.userFirstName || ''} ${
									part.associatedBy[0]?.userLastName || ''
								}`
							}
						/>
						<Column
							body={(part: Api.V1.PartMap.Children.Paged.Get.Res) => (
								<Button
									look={'outlinedPrimary'}
									onClick={() => handleDisassociate(part.partId, partId)}
								>
									Disassociate
								</Button>
							)}
						/>
					</ListDataTable>
				)}
				{assemblyDetails && !ngAllowedPartNumbers.includes(assemblyDetails?.partNumber) && testAuditData && (
					<TestSummaryTable
						partId={partId}
						testAuditData={testAuditData}
						partNumber={assemblyDetails?.partNumber}
					/>
				)}
				<Box display={'flex'} gap={32}>
					{assemblyDetails && <AssemblyNotesPaper assemblyDetails={assemblyDetails} />}
					{assemblyDetails && <SubAssemblyNotesPaper assemblyDetails={assemblyDetails} />}
				</Box>
				{assemblyDetails && <TestResultsTable assemblyDetails={assemblyDetails} />}
			</Box>
		</Page>
	);
};
export default AssemblyDetailsPage;
