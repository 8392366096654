import * as React from 'react';
import './PartTypeSelector.scss';
import { Box, Label, RsFormControl, rsToastify } from '@redskytech/framework/ui';
import SelectableInputText from '../selectableInputText/SelectableInputText';
import serviceFactory from '../../services/serviceFactory';
import { useState } from 'react';
import PageHeader from '../pageHeader/PageHeader';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import useIsMounted from '../../customHooks/useIsMounted';

export type PartRenderType = 'PART_ASSEMBLY' | 'CARRIER_ASSEMBLY' | 'CALIBRATION_FIXTURE_ASSEMBLY' | 'BASE_PART';

interface PartTypeSelectorProps {
	onPartTypeSelected: (assemblyType: PartRenderType, hwid: HardwareIdDecoded) => void;
	labelText?: string;
	pageTitle?: string;
	clearAfterEnter?: boolean;
	rightNode?: React.ReactNode;
	providePageHeader?: boolean;
}

const PartTypeSelector: React.FC<PartTypeSelectorProps> = (props) => {
	const [partHwidControl, setPartHwidControl] = useState<RsFormControl<string>>(
		new RsFormControl<string>('main', '')
	);
	const [isSelected, setIsSelected] = useState<boolean>(true);
	const isMounted = useIsMounted();

	function handleUpdateControl(control: RsFormControl<string>) {
		if (isMounted) setPartHwidControl(control);
	}

	function handleIsSelected() {
		if (isMounted) setIsSelected(true);
	}

	return (
		<>
			{(props.providePageHeader === undefined ? true : props.providePageHeader) && (
				<PageHeader title={props.pageTitle ? props.pageTitle : `Assemble`} rightNode={props.rightNode} />
			)}
			<Box className={'rsAssemblyTypeSelectorSection'}>
				<Label variant={'subheader1'} weight={'regular'}>
					{props.labelText
						? props.labelText
						: 'Scan the hardware identifier of the assembly you want to build.'}
				</Label>
				<SelectableInputText
					labelTitle={'Assembly'}
					control={partHwidControl}
					updateControl={handleUpdateControl}
					isSelected={isSelected}
					onClick={handleIsSelected}
					onBlurOrEnter={async (value) => {
						// Clearing out is ok
						if (!value) return 'VALID';

						const assemblyService = serviceFactory.get('AssemblyService');
						const calibrationService = serviceFactory.get('CalibrationService');
						const hardwareIdDecoded = assemblyService.decodeHardwareId(value);

						if (!hardwareIdDecoded) {
							rsToastify.error('Unable to decode hardware ID.', 'Invalid Hardware ID');
							return 'Invalid Hardware ID';
						}

						const discoveredPartAssemblyType = assemblyService.getPartAssemblyTypeFromPartNumber(
							hardwareIdDecoded.partNumber
						);

						const discoveredBasePartType = assemblyService.getBasePartTypeFromPartNumber(
							hardwareIdDecoded.partNumber
						);

						const discoveredCalibrationAssemblyType =
							calibrationService.getCalibrationAssemblyTypeFromPartNumber(hardwareIdDecoded.partNumber);

						if (
							!discoveredPartAssemblyType &&
							!discoveredCalibrationAssemblyType &&
							!discoveredBasePartType
						) {
							rsToastify.error('Unable to recognize the scanned Part.', 'Unknown Part Type');
							return 'Unknown Part Type';
						}

						if (discoveredPartAssemblyType) {
							props.onPartTypeSelected('PART_ASSEMBLY', hardwareIdDecoded);
						} else if (discoveredBasePartType) {
							props.onPartTypeSelected('BASE_PART', hardwareIdDecoded);
						} else if (discoveredCalibrationAssemblyType) {
							if (discoveredCalibrationAssemblyType === 'CARRIER_ASSEMBLY')
								props.onPartTypeSelected('CARRIER_ASSEMBLY', hardwareIdDecoded);
							else if (discoveredCalibrationAssemblyType === 'CALIBRATION_FIXTURE_ASSEMBLY')
								props.onPartTypeSelected('CALIBRATION_FIXTURE_ASSEMBLY', hardwareIdDecoded);
						}

						if (props.clearAfterEnter) {
							setPartHwidControl(new RsFormControl<string>('main', ''));
						}

						return 'VALID';
					}}
				/>
			</Box>
		</>
	);
};

export default PartTypeSelector;
