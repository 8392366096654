import { Box, Label } from '@redskytech/framework/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { StringUtils } from '../../../utils/utils';
import './TestCriteriaTable.scss';

import { ITestSuite, ITestCriteria, ITestDefinition, convertOperatorToSymbol } from '../../../utils/testCriteria';
import serviceFactory from '../../../services/serviceFactory';
export interface TestCriteriaTableProps {
	testSuite: ITestSuite;
}

const TestCriteriaTable: React.FC<TestCriteriaTableProps> = (props) => {
	const [rows, setRows] = useState<JSX.Element[]>([]);
	const assemblyService = serviceFactory.get('AssemblyService');
	useEffect(() => {
		(async () => {
			const rows = await renderRows();
			setRows(rows);
		})();
	}, [props.testSuite]);

	async function renderRows() {
		const rows: JSX.Element[] = [];

		if (!props.testSuite.tests.length) return rows;

		function renderRow(test: ITestDefinition, criteria?: ITestCriteria): JSX.Element {
			let criteriaValue = criteria?.value;
			// if criteria .value is a part type or assembly type, lets convert it to a label
			if (typeof criteriaValue === 'string') {
				console.log('string detected');
				const partLabel = assemblyService.getLabelFromPartType(criteriaValue);
				if (partLabel !== '') {
					criteriaValue = partLabel;
				}
			}
			return (
				<tr key={`${test.testName}${criteria?.fieldName}${criteria?.operator}${criteria?.value}`}>
					<td>
						{!criteria && (
							<Label variant="caption1" weight="regular" ml={0} maxWidth={80}>
								{StringUtils.convertCamelCaseToHuman(test.testName)}
							</Label>
						)}
					</td>
					<td>{criteria && StringUtils.convertCamelCaseToHuman(criteria.fieldName)}</td>
					<td>{criteria && convertOperatorToSymbol(criteria.operator)}</td>
					<td>{criteria && String(criteriaValue)}</td>
				</tr>
			);
		}

		for (const item of props.testSuite.tests) {
			rows.push(renderRow(item));

			if (item.testCriteria && item.testCriteria.length) {
				for (const child of item.testCriteria) {
					rows.push(renderRow(item, child));
				}
			}
		}

		return rows;
	}

	return (
		<Box className="rsTestCriteriaTableElement" key={`${props.testSuite.testSuiteName}_table`}>
			<Label variant="h6" weight="bold">
				{props.testSuite.testSuiteName}
			</Label>
			<table>
				<thead>
					<tr className="header">
						<th>
							<Label variant="caption2" weight="regular">
								Test Name
							</Label>
						</th>
						<th>
							<Label variant="caption2" weight="regular">
								Criteria Name
							</Label>
						</th>
						<th>
							<Label variant="caption2" weight="regular">
								Operator
							</Label>
						</th>
						<th>
							<Label variant="caption2" weight="regular">
								Target Value
							</Label>
						</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		</Box>
	);
};

export default TestCriteriaTable;
