import * as React from 'react';
import { Box, InputText, popupController, RsFormControl, RsFormGroup, rsToastify } from '@redskytech/framework/ui';

import ListDataTable from '../../components/listDataTable/ListDataTable';

import router from '../../utils/router';
import { useState } from 'react';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import useDataTableFilters from '../../customHooks/useDataTableFilters';
import {
	DataTableFilterMeta,
	DataTableFilterMetaData,
	DataTableOperatorFilterMetaData,
	DataTableRowClickEventParams
} from 'primereact/datatable';
import useIsMounted from '../../customHooks/useIsMounted';
import { ApiRequestV1 } from '../../generated/apiRequests';
import { DateUtils, WebUtils } from '../../utils/utils';

import classNames from 'classnames';
import ColumnHeader from '../../components/listDataTable/columnHeader/ColumnHeader';
import './TestCriteriaList.scss';
import TestCriteriaReportPopup, {
	TestCriteriaReportPopupProps
} from '../../popups/testCriteriaReportPopup/TestCriteriaReportPopup';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TestCriteriaListProps {}

const FieldKeys = {
	SHIM_VERSION: 'testDefinition.shimVersion',
	WEB_VERSION: 'testDefinition.webVersion',
	TOOLS_VERSION: 'testDefinition.toolsVersion',
	CREATED_ON: 'testDefinition.createdOn',
	CREATED_FIRST_NAME: 'createdById_user.firstName',
	CREATED_LAST_NAME: 'createdById_user.lastName'
};

const TestCriteriaList: React.FC<TestCriteriaListProps> = () => {
	const [criteriaRecords, setCriteriaRecords] = useState<
		RedSky.RsPagedResponseData<Api.V1.Definition.Test.Paged.Get.Res[]>
	>({
		data: [],
		total: 0
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeSortColumn, setActiveSortColumn] = useState<string>();

	const queryParams = router.getQueryParams<{
		sortField: string;
		sortOrder: string;
		globalSearch: string;
		page: number;
		perPage: number;
		filter: string;
	}>([
		{
			key: 'sortField',
			default: FieldKeys.CREATED_ON,
			type: 'string'
		},
		{
			key: 'sortOrder',
			default: 'DESC',
			type: 'string'
		},
		{
			key: 'page',
			default: 1,
			type: 'integer'
		},
		{
			key: 'perPage',
			default: 50,
			type: 'integer'
		},
		{
			key: 'filter',
			default: '',
			type: 'string'
		}
	]);

	const initialFiltersFormGroup: RsFormGroup = new RsFormGroup([
		new RsFormControl<string>(FieldKeys.SHIM_VERSION, getQueryParamValueForFilter(FieldKeys.SHIM_VERSION) || ''),
		new RsFormControl<string>(FieldKeys.WEB_VERSION, getQueryParamValueForFilter(FieldKeys.WEB_VERSION) || ''),
		new RsFormControl<string>(FieldKeys.TOOLS_VERSION, getQueryParamValueForFilter(FieldKeys.TOOLS_VERSION) || ''),
		new RsFormControl<string>(FieldKeys.CREATED_ON, getQueryParamValueForFilter(FieldKeys.CREATED_ON) || ''),
		new RsFormControl<string>(
			FieldKeys.CREATED_FIRST_NAME,
			getQueryParamValueForFilter(FieldKeys.CREATED_FIRST_NAME) || ''
		)
	]);
	const initialFilters: DataTableFilterMeta = {
		[FieldKeys.SHIM_VERSION]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.SHIM_VERSION), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.WEB_VERSION]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.WEB_VERSION), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.TOOLS_VERSION]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.TOOLS_VERSION), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.CREATED_ON]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.CREATED_ON), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.CREATED_FIRST_NAME]: {
			operator: FilterOperator.AND,
			constraints: [
				{
					value: getQueryParamValueForFilter(FieldKeys.CREATED_FIRST_NAME),
					matchMode: FilterMatchMode.CONTAINS
				}
			]
		},
		[FieldKeys.CREATED_LAST_NAME]: {
			operator: FilterOperator.OR,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.CREATED_LAST_NAME), matchMode: FilterMatchMode.CONTAINS }
			]
		}
	};

	const {
		filters,
		activeFilters,
		globalSearch,
		handleClearAllFilters,
		handleFilterClear,
		handleFilterApply,
		handleUpdateControl,
		filtersFormGroup
	} = useDataTableFilters(initialFilters, initialFiltersFormGroup, getUpdatedFilters, queryParams.globalSearch);
	const isMounted = useIsMounted();

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);

		const filterValues: string[] = [];
		for (const i in filters) {
			if (Object.prototype.hasOwnProperty.call(filters[i], 'constraints')) {
				(filters[i] as DataTableOperatorFilterMetaData).constraints.forEach(
					(constraint: DataTableFilterMetaData) => {
						if (constraint.value !== null) filterValues.push(`${i}_${constraint.value}`);
					}
				);
			}
		}
		router.updateQueryParams({
			sortField: pageQuery.sortBy || '',
			sortOrder: pageQuery.sortOrder || -1,
			globalSearch,
			page: pageQuery.page || 1,
			perPage: pageQuery.perPage || 50,
			...(filterValues.length && { filter: filterValues.join(',') })
		});

		try {
			if (!isMounted) return;
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;
			if (pageQuery.page === undefined || pageQuery.perPage === undefined) return;
			const res = await ApiRequestV1.getDefinitionTestPaged({
				page: pageQuery.page || 1,
				perPage: pageQuery.perPage || 50,
				...pageQuery
			});
			setCriteriaRecords(res);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
		setIsLoading(false);
	}

	function getQueryParamValueForFilter(filterName: string): string | null {
		// Quick check to see if it's in here
		if (!queryParams.filter.includes(filterName)) return null;
		const filters = queryParams.filter.split(',');
		const filter = filters.find((filter) => filter.includes(filterName));
		if (!filter) return null;
		const filterSplit = filter.split('_');
		if (filterSplit.length !== 2) return null;
		return filterSplit[1];
	}

	function getUpdatedFilters(field: string, value: any) {
		const filter: any = filters[field];

		const constraint = [
			{
				value: value,
				matchMode: filter?.constraints[0]?.matchMode
			}
		];
		if (field === FieldKeys.CREATED_FIRST_NAME) {
			return {
				...filters,
				[field]: {
					...filter,
					constraints: constraint
				},
				[FieldKeys.CREATED_LAST_NAME]: {
					operator: FilterOperator.OR,
					constraints: constraint
				}
			};
		}
		return {
			...filters,
			[field]: {
				...filter,
				constraints: constraint
			}
		};
	}

	function handleRowClick({ data }: DataTableRowClickEventParams) {
		console.log(data);
		popupController.open<TestCriteriaReportPopupProps>(TestCriteriaReportPopup, {
			summaryData: [],
			sourceTestSuite: {
				webVersion: data.webVersion,
				shimVersion: data.shimVersion,
				toolsVersion: data.toolsVersion
			}
		});
	}

	return (
		<Box className={'rsTestCriteriaList'}>
			<ListDataTable
				loading={isLoading}
				onClearAllFilters={handleClearAllFilters}
				onFilterClear={handleFilterClear}
				onFilterApply={handleFilterApply}
				filters={filters}
				rowsPerPageOptions={[50, 250, 500]}
				data={criteriaRecords}
				getData={getData}
				initialRowsPerPage={queryParams.perPage}
				first={(queryParams.page - 1) * queryParams.perPage}
				globalFilter={globalSearch}
				globalFilterFields={[
					FieldKeys.SHIM_VERSION,
					FieldKeys.WEB_VERSION,
					FieldKeys.TOOLS_VERSION,
					FieldKeys.CREATED_FIRST_NAME,
					FieldKeys.CREATED_LAST_NAME
				]}
				sortField={queryParams.sortField}
				sortOrder={queryParams.sortOrder === 'ASC' ? 1 : -1}
				onRowClick={handleRowClick}
			>
				<Column
					className={classNames({ activeFilter: activeFilters.includes(FieldKeys.WEB_VERSION) })}
					field={FieldKeys.WEB_VERSION}
					sortField={FieldKeys.WEB_VERSION}
					header={
						<ColumnHeader label={'App Version'} isActiveSort={activeSortColumn === FieldKeys.WEB_VERSION} />
					}
					sortable
					filter
					filterElement={
						<InputText
							inputMode={'text'}
							placeholder={'Version #'}
							control={filtersFormGroup.get(FieldKeys.WEB_VERSION)}
							updateControl={handleUpdateControl}
						/>
					}
					body={(criteria: Api.V1.Definition.Test.Paged.Get.Res) => criteria.webVersion}
				/>
				<Column
					className={classNames({ activeFilter: activeFilters.includes(FieldKeys.SHIM_VERSION) })}
					field={FieldKeys.SHIM_VERSION}
					sortField={FieldKeys.SHIM_VERSION}
					header={
						<ColumnHeader
							label={'Shim Version'}
							isActiveSort={activeSortColumn === FieldKeys.SHIM_VERSION}
						/>
					}
					sortable
					filter
					filterElement={
						<InputText
							inputMode={'text'}
							placeholder={'Version #'}
							control={filtersFormGroup.get(FieldKeys.SHIM_VERSION)}
							updateControl={handleUpdateControl}
						/>
					}
					body={(criteria: Api.V1.Definition.Test.Paged.Get.Res) => criteria.shimVersion}
				/>
				<Column
					className={classNames({ activeFilter: activeFilters.includes(FieldKeys.TOOLS_VERSION) })}
					field={FieldKeys.TOOLS_VERSION}
					sortField={FieldKeys.TOOLS_VERSION}
					header={
						<ColumnHeader
							label={'Tools Version'}
							isActiveSort={activeSortColumn === FieldKeys.TOOLS_VERSION}
						/>
					}
					sortable
					filter
					filterElement={
						<InputText
							inputMode={'text'}
							placeholder={'Version #'}
							control={filtersFormGroup.get(FieldKeys.TOOLS_VERSION)}
							updateControl={handleUpdateControl}
						/>
					}
					body={(criteria: Api.V1.Definition.Test.Paged.Get.Res) => criteria.toolsVersion}
				/>
				<Column
					className={classNames({ activeFilter: activeFilters.includes(FieldKeys.CREATED_ON) })}
					field={FieldKeys.CREATED_ON}
					sortField={FieldKeys.CREATED_ON}
					header={
						<ColumnHeader label={'Created On'} isActiveSort={activeSortColumn === FieldKeys.CREATED_ON} />
					}
					sortable
					filter
					filterElement={
						<InputText
							inputMode={'text'}
							type={'date'}
							control={filtersFormGroup.get(FieldKeys.CREATED_ON)}
							updateControl={(control) => {
								control.value = DateUtils.convertInputDateToServerDate(control.value.toString());
								handleUpdateControl(control);
							}}
						/>
					}
					body={(part: Api.V1.Part.Paged.Get.Res) => DateUtils.displayDate(part.createdOn)}
				/>
				<Column
					className={classNames({ activeFilter: activeFilters.includes(FieldKeys.CREATED_FIRST_NAME) })}
					field={FieldKeys.CREATED_FIRST_NAME}
					sortField={FieldKeys.CREATED_FIRST_NAME}
					header={
						<ColumnHeader
							label={'Created By'}
							isActiveSort={activeSortColumn === FieldKeys.CREATED_FIRST_NAME}
						/>
					}
					sortable
					filter
					filterElement={
						<InputText
							inputMode={'text'}
							placeholder={'Created By'}
							control={filtersFormGroup.get(FieldKeys.CREATED_FIRST_NAME)}
							updateControl={handleUpdateControl}
						/>
					}
					body={(criteria: Api.V1.Definition.Test.Paged.Get.Res) =>
						`${criteria.userFirstName} ${criteria.userLastName}`
					}
				/>
			</ListDataTable>
		</Box>
	);
};
export default TestCriteriaList;
