import { Box, Img, Label } from '@redskytech/framework/ui';
import { ObjectUtils } from '@redskytech/framework/utils';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import { KitGroupType } from '../../services/kit/IKitService';
import globalState from '../../state/globalState';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import './KitCheckoutPdf.scss';
import KitCheckoutTable from './kitCheckoutTable/KitCheckoutTable';

export interface KitCheckoutPdfProps {
	serialNumber: string;
	salesOrder: string;
	summaries: any[];
	kitCheckoutGroup?: { group: KitGroupType; idList: HardwareIdDecoded[] }[];
}

const KitCheckoutPdf: React.FC<KitCheckoutPdfProps> = (props) => {
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const [groupedSummaryData, setGroupedSummaryData] = useState<any[]>(props.summaries);

	const renderedKitCheckoutGroups = useMemo(() => {
		return renderKitCheckoutGroups();
	}, [props.summaries]);

	const renderedKitTestSummary = useMemo(() => {
		return renderKitTestSummary();
	}, [groupedSummaryData]);

	useEffect(() => {
		setGroupedSummaryData(props.summaries);
	}, [props.summaries]);

	function renderKitCheckoutGroups() {
		const nodes = props.kitCheckoutGroup
			?.map((group, index) => {
				if (!group.idList.length) return false;
				else return <KitCheckoutTable key={`rsKitCheckoutTable${group.group}${index}`} kitGroup={group} />;
			})
			.filter(Boolean);
		nodes?.push(<div style={{ 'break-after': 'page' }} />);
		return nodes;
	}

	function renderStatusChip(status: string) {
		if (status === 'INCOMPLETE' || status === 'MISSING') {
			return (
				<Label variant="caption1" weight="regular" color={'#e6900f'}>
					Incomplete
				</Label>
			);
		} else if (status === 'FAIL') {
			return (
				<Label variant="caption1" weight="regular" color={'#750800'}>
					Fail
				</Label>
			);
		} else {
			return (
				<Label variant="caption1" weight="regular" color={'#20a612'}>
					Pass
				</Label>
			);
		}
	}

	function renderTestSummaryRows(subTest: any[]) {
		return subTest.map((row) => {
			return (
				<tr>
					<td>
						<Label variant="caption2" weight="regular">
							{row.testName}
						</Label>
					</td>
					<td>
						<Label variant="caption2" weight="regular">
							{row.createdOn ? new Date(row.createdOn).toLocaleDateString() : 'N/A'}
						</Label>
					</td>
					<td>
						<Label variant="caption2" weight="regular">
							{renderStatusChip(row.status)}
						</Label>
					</td>
				</tr>
			);
		});
	}

	function renderTestSummaryTables(test: any[]) {
		return test.map((item) => {
			return (
				<Box className="testTableContent">
					<Label variant="caption1" weight="semiBold" className="testName">
						{item.group}
					</Label>
					<table>
						<thead>
							<tr className="header">
								<th>
									<Label variant="caption2" weight="regular">
										Test
									</Label>
								</th>
								<th>
									<Label variant="caption2" weight="regular">
										Tested On
									</Label>
								</th>
								<th>
									<Label variant="caption2" weight="regular">
										Status
									</Label>
								</th>
							</tr>
						</thead>
						<tbody>{renderTestSummaryRows(item.tests)}</tbody>
					</table>
				</Box>
			);
		});
	}

	function renderKitTestSummary() {
		if (!ObjectUtils.isArrayWithData(groupedSummaryData)) return;

		let nodeArray = [];

		let previousAssemblyType = '';
		let previousGroupName = '';

		nodeArray = groupedSummaryData.map((group) => {
			let assemblyType = group.assemblyType;
			if (previousGroupName === group.name) assemblyType = previousAssemblyType;
			else previousAssemblyType = group.assemblyType;

			previousGroupName = group.name;
			return (
				<Box className="summaryTable">
					<Box display="flex" mb="4px">
						<Box>
							<Label variant="caption2" weight="regular">
								{assemblyType}
							</Label>
							<Label variant="body2" weight="bold">
								{group.name}
							</Label>
						</Box>
						{group.subAssemblyName && (
							<>
								<hr />
								<Box>
									<Label variant="caption2" weight="regular" color={'#495057'}>
										{group.assemblyType}
									</Label>
									<Label variant="caption1" weight="semiBold" color={'#495057'}>
										Subassembly {group.subAssemblyName}
									</Label>
								</Box>
							</>
						)}
					</Box>

					{!group.testGroup.length ? (
						<Label variant="caption2" weight="regular" textAlign="center" m="16px 0">
							<span style={{ 'font-style': 'italic' }}>No test required for this assembly</span>
						</Label>
					) : (
						<>
							<Box className="testTableWrapper">{renderTestSummaryTables(group.testGroup)}</Box>
							<div style={{ 'break-after': 'page' }} />
						</>
					)}
				</Box>
			);
		});
		return nodeArray;
	}

	return (
		<Box className="rsKitCheckoutPdf">
			<Box className="pdfHeader">
				<Box borderRight={'1px solid var(--Neutral-Grey-400, #CED4DA)'}>
					<Box p="0 8px 8px 0" borderBottom={'1px solid var(--Neutral-Grey-400, #CED4DA)'}>
						<Label variant={'h4'} weight={'semiBold'}>
							Final Kit Verification
						</Label>
					</Box>
					<Box display="grid" gridTemplateColumns="1fr 1fr" p={'8px 0'}>
						<Box>
							<Label variant="caption2" weight="regular">
								Serial Number
							</Label>
							<Label variant="caption1" weight="bold">
								{props.serialNumber}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Sales Order
							</Label>
							<Label variant="caption1" weight="bold">
								{props.salesOrder}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Checked By
							</Label>
							<Label variant="caption1" weight="bold">
								{user?.firstName} {user?.lastName}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Date
							</Label>
							<Label variant="caption1" weight="bold">
								{new Date().toDateString()}
							</Label>
						</Box>
					</Box>
				</Box>
				<Box pb={8}>
					<Img
						src={'/pdfStatic/logo_black_wordmark.png'}
						alt="FLIR Black Logo"
						width="136px"
						height="31px"
						mb={8}
					/>
					<Box>
						<Label variant="caption2" weight="regular">
							100 Midland Road
						</Label>
						<Label variant="caption2" weight="regular">
							Oak Ridge, TN 37830
						</Label>
						<Label variant="caption2" weight="regular">
							T +1.865.220.8700x101
						</Label>
						<Label variant="caption2" weight="regular">
							F +1.865.220.7181
						</Label>
					</Box>
				</Box>
			</Box>
			{!groupedSummaryData.length && !renderedKitCheckoutGroups ? (
				<Box mt="16px" position="relative" height="250px">
					<LoadingSpinner />
				</Box>
			) : (
				<>
					{renderedKitCheckoutGroups}
					{renderedKitTestSummary}
				</>
			)}
		</Box>
	);
};

export default KitCheckoutPdf;
