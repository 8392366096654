import http from '../utils/http';

/**
 * V1 Endpoints
 * @summary V1 
 */
export namespace ApiRequestV1 {
	/**
	 * Get my user
	 * @summary get my user 
	 */
	export async function getUserMe() : Promise<Api.V1.User.Me.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.User.Me.Get.Res>, void>('/user/me');
		return res.data.data;
	}

	/**
	 * User login endpoint
	 * @summary Login 
	 */
	export async function postUserLogin(req: Api.V1.User.Login.Post.Req) : Promise<CustomTypes.LoginResponse> {
		let res = await http.post<RedSky.RsResponseData<CustomTypes.LoginResponse>, Api.V1.User.Login.Post.Req>('/user/login', req);
		return res.data.data;
	}

	/**
	 * Refresh an old, possibly expired token and returns a new token.
	 * @summary Re-Authenticates a Token 
	 */
	export async function postUserReAuth(req: Api.V1.User.ReAuth.Post.Req) : Promise<CustomTypes.ReAuthResponse> {
		let res = await http.post<RedSky.RsResponseData<CustomTypes.ReAuthResponse>, Api.V1.User.ReAuth.Post.Req>('/user/re-auth', req);
		return res.data.data;
	}

	/**
	 * Get a paginated list of users.
	 * @summary Paginated Users 
	 */
	export async function getUserPaged(req: Api.V1.User.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.User.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.User.Paged.Get.Res[]>, Api.V1.User.Paged.Get.Req>('/user/paged', req);
		return res.data;
	}

	/**
	 * Create a new user.
	 * @summary Create User 
	 */
	export async function postUser(req: Api.V1.User.Post.Req) : Promise<CustomTypes.FilteredUser> {
		let res = await http.post<RedSky.RsResponseData<CustomTypes.FilteredUser>, Api.V1.User.Post.Req>('/user', req);
		return res.data.data;
	}

	/**
	 * Update an existing user.
	 * @summary Update User 
	 */
	export async function patchUser(req: Api.V1.User.Patch.Req) : Promise<CustomTypes.FilteredUser> {
		let res = await http.patch<RedSky.RsResponseData<CustomTypes.FilteredUser>, Api.V1.User.Patch.Req>('/user', req);
		return res.data.data;
	}

	/**
	 * Get a user by id.
	 * @summary Get User 
	 */
	export async function getUser(req: Api.V1.User.Get.Req) : Promise<Api.V1.User.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.User.Get.Res>, Api.V1.User.Get.Req>('/user', req);
		return res.data.data;
	}

	/**
	 * Update my user
	 * @summary Update my user 
	 */
	export async function patchUserMe(req: Api.V1.User.Me.Patch.Req) : Promise<Api.V1.User.Me.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.User.Me.Patch.Res>, Api.V1.User.Me.Patch.Req>('/user/me', req);
		return res.data.data;
	}

	/**
	 * Get a paginated list of parts.
	 * @summary Parts Paged 
	 */
	export async function getPartPaged(req: Api.V1.Part.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Part.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Part.Paged.Get.Res[]>, Api.V1.Part.Paged.Get.Req>('/part/paged', req);
		return res.data;
	}

	/**
	 * Get part details by Id
	 * @summary Get Part By Id 
	 */
	export async function getPart(req: Api.V1.Part.Get.Req) : Promise<Api.V1.Part.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Part.Get.Res>, Api.V1.Part.Get.Req>('/part', req);
		return res.data.data;
	}

	/**
	 * Get a paginated list of action logs
	 * @summary Action Log Paged 
	 */
	export async function getActionLogPaged(req: Api.V1.ActionLog.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.ActionLog.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.ActionLog.Paged.Get.Res[]>, Api.V1.ActionLog.Paged.Get.Req>('/action-log/paged', req);
		return res.data;
	}

	/**
	 * Get a paginated list of part maps
	 * @summary Child Part Map Paged 
	 */
	export async function getPartMapChildrenPaged(req: Api.V1.PartMap.Children.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.PartMap.Children.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.PartMap.Children.Paged.Get.Res[]>, Api.V1.PartMap.Children.Paged.Get.Req>('/part-map/children/paged', req);
		return res.data;
	}

	/**
	 * Disassociate Part Map
	 * @summary Delete Part Map 
	 */
	export async function deletePartMap(req: Api.V1.PartMap.Delete.Req) : Promise<Api.V1.PartMap.Delete.Res> {
		let res = await http.delete<RedSky.RsResponseData<Api.V1.PartMap.Delete.Res>, Api.V1.PartMap.Delete.Req>('/part-map', req);
		return res.data.data;
	}

	/**
	 * Returns the current version of the shim application
	 * @summary Shim Version 
	 */
	export async function getShimVersion() : Promise<Api.V1.Shim.Version.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Shim.Version.Get.Res>, void>('/shim/version');
		return res.data.data;
	}

	/**
	 * Get part details by Serial and Part Number
	 * @summary Get Part By Serial and Part Number 
	 */
	export async function getPartByNumbers(req: Api.V1.Part.By.Numbers.Get.Req) : Promise<Api.V1.Part.By.Numbers.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Part.By.Numbers.Get.Res>, Api.V1.Part.By.Numbers.Get.Req>('/part/by/numbers', req);
		return res.data.data;
	}

	/**
	 * Updates or inserts a part with children or removes children if none are given
	 * @summary Assembly Part 
	 */
	export async function postPartAssemble(req: CustomTypes.AssemblyRequest) : Promise<boolean> {
		let res = await http.post<RedSky.RsResponseData<boolean>, CustomTypes.AssemblyRequest>('/part/assemble', req);
		return res.data.data;
	}

	/**
	 * Returns the paginated test results
	 * @summary Get Test Results Paged 
	 */
	export async function getTestResultPaged(req: Api.V1.TestResult.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.TestResult.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.TestResult.Paged.Get.Res[]>, Api.V1.TestResult.Paged.Get.Req>('/test-result/paged', req);
		return res.data;
	}

	/**
	 * Creates a test result
	 * @summary Create Test Result 
	 */
	export async function postTestResult(req: CustomTypes.PostTestResultRequest) : Promise<CustomTypes.PostTestResultResponse> {
		let res = await http.post<RedSky.RsResponseData<CustomTypes.PostTestResultResponse>, CustomTypes.PostTestResultRequest>('/test-result', req);
		return res.data.data;
	}

	/**
	 * 
	 * @summary Update Test Results 
	 */
	export async function patchTestResult(req: Api.V1.TestResult.Patch.Req) : Promise<Api.V1.TestResult.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.TestResult.Patch.Res>, Api.V1.TestResult.Patch.Req>('/test-result', req);
		return res.data.data;
	}

	/**
	 * Creates a test result
	 * @summary Create Test Result 
	 */
	export async function getTestResult(req: Api.V1.TestResult.Get.Req) : Promise<Api.V1.TestResult.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.TestResult.Get.Res>, Api.V1.TestResult.Get.Req>('/test-result', req);
		return res.data.data;
	}

	/**
	 * Returns the carrier given its serial number and part number
	 * @summary Get Carrier Details by Numbers 
	 */
	export async function getCarrierByNumbers(req: Api.V1.Carrier.By.Numbers.Get.Req) : Promise<Api.V1.Carrier.By.Numbers.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Carrier.By.Numbers.Get.Res>, Api.V1.Carrier.By.Numbers.Get.Req>('/carrier/by/numbers', req);
		return res.data.data;
	}

	/**
	 * Assmebles a calibration carrier card with the parts
	 * @summary Assembly Calibration Carrier 
	 */
	export async function postCarrierAssemble(req: CustomTypes.CarrierAssemblyRequest) : Promise<boolean> {
		let res = await http.post<RedSky.RsResponseData<boolean>, CustomTypes.CarrierAssemblyRequest>('/carrier/assemble', req);
		return res.data.data;
	}

	/**
	 * Returns a calibration fixture by using part number and serial number
	 * @summary Gets a Calibration Fixture by Numbers 
	 */
	export async function getCalFixtureByNumbers(req: Api.V1.CalFixture.By.Numbers.Get.Req) : Promise<Api.V1.CalFixture.By.Numbers.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.CalFixture.By.Numbers.Get.Res>, Api.V1.CalFixture.By.Numbers.Get.Req>('/cal-fixture/by/numbers', req);
		return res.data.data;
	}

	/**
	 * Returns a carrier by using its database id
	 * @summary Get Carrier 
	 */
	export async function getCarrier(req: Api.V1.Carrier.Get.Req) : Promise<Api.V1.Carrier.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Carrier.Get.Res>, Api.V1.Carrier.Get.Req>('/carrier', req);
		return res.data.data;
	}

	/**
	 * Upserts a cal fixture
	 * @summary Create or Update Cal Fixture 
	 */
	export async function postCalFixture(req: CustomTypes.CalFixtureRequest) : Promise<CustomTypes.CalFixtureResponse> {
		let res = await http.post<RedSky.RsResponseData<CustomTypes.CalFixtureResponse>, CustomTypes.CalFixtureRequest>('/cal-fixture', req);
		return res.data.data;
	}

	/**
	 * Gets a presigned URL
	 * @summary Request AWS S3 Presigned URL 
	 */
	export async function getTestResultPresignedUrl(req: Api.V1.TestResult.PresignedUrl.Get.Req) : Promise<string> {
		let res = await http.get<RedSky.RsResponseData<string>, Api.V1.TestResult.PresignedUrl.Get.Req>('/test-result/presigned-url', req);
		return res.data.data;
	}

	/**
	 * Create a new result
	 * @summary Create Result 
	 */
	export async function postResult(req: Api.V1.Result.Post.Req) : Promise<Api.V1.Result.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.Result.Post.Res>, Api.V1.Result.Post.Req>('/result', req);
		return res.data.data;
	}

	/**
	 * Update a result
	 * @summary Update Result 
	 */
	export async function patchResult(req: Api.V1.Result.Patch.Req) : Promise<Api.V1.Result.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Result.Patch.Res>, Api.V1.Result.Patch.Req>('/result', req);
		return res.data.data;
	}

	/**
	 * Returns a summary of the test results for a given part id
	 * @summary Test Result Summary 
	 */
	export async function getTestResultSummary(req: Api.V1.TestResult.Summary.Get.Req) : Promise<CustomTypes.TestResultSummaryResponse> {
		let res = await http.get<RedSky.RsResponseData<CustomTypes.TestResultSummaryResponse>, Api.V1.TestResult.Summary.Get.Req>('/test-result/summary', req);
		return res.data.data;
	}

	/**
	 * Returns the current version of the shim application
	 * @summary Web App Version 
	 */
	export async function getWebVersion() : Promise<Api.V1.Web.Version.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Web.Version.Get.Res>, void>('/web/version');
		return res.data.data;
	}

	/**
	 * Can be sent to reset the expiration time of the refresh token, indefinitely
	 * @summary Keep User Alive 
	 */
	export async function postUserKeepAlive(req: Api.V1.User.KeepAlive.Post.Req) : Promise<boolean> {
		let res = await http.post<RedSky.RsResponseData<boolean>, Api.V1.User.KeepAlive.Post.Req>('/user/keep-alive', req);
		return res.data.data;
	}

	/**
	 * Get kit info by Id
	 * @summary Get kit by id 
	 */
	export async function getKit(req: Api.V1.Kit.Get.Req) : Promise<Api.V1.Kit.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Kit.Get.Res>, Api.V1.Kit.Get.Req>('/kit', req);
		return res.data.data;
	}

	/**
	 * Updates or inserts parts into a kit assembly.
	 * @summary Assemble Kit 
	 */
	export async function postKitAssemble(req: CustomTypes.KitRequest) : Promise<boolean> {
		let res = await http.post<RedSky.RsResponseData<boolean>, CustomTypes.KitRequest>('/kit/assemble', req);
		return res.data.data;
	}

	/**
	 * Get kit info by kitNumber
	 * @summary Get kit by number 
	 */
	export async function getKitByNumbers(req: Api.V1.Kit.By.Numbers.Get.Req) : Promise<Api.V1.Kit.By.Numbers.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Kit.By.Numbers.Get.Res>, Api.V1.Kit.By.Numbers.Get.Req>('/kit/by/numbers', req);
		return res.data.data;
	}

	/**
	 * get the next valid kit number
	 * @summary Next Kit Number 
	 */
	export async function getKitNext() : Promise<string> {
		let res = await http.get<RedSky.RsResponseData<string>, void>('/kit/next');
		return res.data.data;
	}

	/**
	 * Update some notes
	 * @summary Update Notes on Assembly 
	 */
	export async function patchPartAssembleNotes(req: Api.V1.Part.Assemble.Notes.Patch.Req) : Promise<Api.V1.Part.Assemble.Notes.Patch.Res> {
		let res = await http.patch<RedSky.RsResponseData<Api.V1.Part.Assemble.Notes.Patch.Res>, Api.V1.Part.Assemble.Notes.Patch.Req>('/part/assemble/notes', req);
		return res.data.data;
	}

	/**
	 * Gets checklist from the part assembly.
	 * @summary Get Part Checklist 
	 */
	export async function getPartChecklist(req: Api.V1.Part.Checklist.Get.Req) : Promise<Api.V1.Part.Checklist.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Part.Checklist.Get.Res>, Api.V1.Part.Checklist.Get.Req>('/part/checklist', req);
		return res.data.data;
	}

	/**
	 * Return a custom CSV Report
	 * @summary Custom CSV Reports 
	 */
	export async function postReport(req: CustomTypes.CustomCsvReportRequest) : Promise<CustomTypes.CustomCsvReportResponse> {
		let res = await http.post<RedSky.RsResponseData<CustomTypes.CustomCsvReportResponse>, CustomTypes.CustomCsvReportRequest>('/report', req);
		return res.data.data;
	}

	/**
	 * Get whole family tree based on part id 
	 * @summary Get Family Tree 
	 */
	export async function getPartFamilyTree(req: CustomTypes.PartFamilyTreeRequest) : Promise<CustomTypes.PartFamilyTreeResponse> {
		let res = await http.get<RedSky.RsResponseData<CustomTypes.PartFamilyTreeResponse>, CustomTypes.PartFamilyTreeRequest>('/part/family-tree', req);
		return res.data.data;
	}

	/**
	 * Get the KitNumber by partId
	 * @summary GET kitNumber by partId 
	 */
	export async function getKitByPartId(req: Api.V1.Kit.By.PartId.Get.Req) : Promise<Api.V1.Kit.By.PartId.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Kit.By.PartId.Get.Res>, Api.V1.Kit.By.PartId.Get.Req>('/kit/by/partId', req);
		return res.data.data;
	}

	/**
	 * Get a paginated list of kits
	 * @summary Kit Paged 
	 */
	export async function getKitPaged(req: Api.V1.Kit.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Kit.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Kit.Paged.Get.Res[]>, Api.V1.Kit.Paged.Get.Req>('/kit/paged', req);
		return res.data;
	}

	/**
	 * Returns a summary of all tests that have been run against this part, Sort these by testResultId and store the test results by test suite/session type, keeping the complete list of most recent results. ( so it's a set, only keeping the most recent of each test
	 * @summary Test Result Audit Info 
	 */
	export async function getTestResultAuditSummary(req: Api.V1.TestResult.AuditSummary.Get.Req) : Promise<CustomTypes.TestResultAuditResponse> {
		let res = await http.get<RedSky.RsResponseData<CustomTypes.TestResultAuditResponse>, Api.V1.TestResult.AuditSummary.Get.Req>('/test-result/audit-summary', req);
		return res.data.data;
	}

	/**
	 * Upload Full definition of all tests and their criteria
	 * @summary Upload Full Test Definition Document 
	 */
	export async function postDefinitionTest(req: Api.V1.Definition.Test.Post.Req) : Promise<Api.V1.Definition.Test.Post.Res> {
		let res = await http.post<RedSky.RsResponseData<Api.V1.Definition.Test.Post.Res>, Api.V1.Definition.Test.Post.Req>('/definition/test', req);
		return res.data.data;
	}

	/**
	 * Retrieve Full Test Suite Definition Document
	 * @summary Retrieve Test Definition for Version Set 
	 */
	export async function getDefinitionTest(req: Api.V1.Definition.Test.Get.Req) : Promise<Api.V1.Definition.Test.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Definition.Test.Get.Res>, Api.V1.Definition.Test.Get.Req>('/definition/test', req);
		return res.data.data;
	}

	/**
	 * Get a paginated list of test Definitions
	 * @summary Test Definitions Paged 
	 */
	export async function getDefinitionTestPaged(req: Api.V1.Definition.Test.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Definition.Test.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Definition.Test.Paged.Get.Res[]>, Api.V1.Definition.Test.Paged.Get.Req>('/definition/test/paged', req);
		return res.data;
	}

	/**
	 * Get inspection by Id
	 * @summary Get inspection by Id 
	 */
	export async function getInspection(req: Api.V1.Inspection.Get.Req) : Promise<Api.V1.Inspection.Get.Res> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Inspection.Get.Res>, Api.V1.Inspection.Get.Req>('/inspection', req);
		return res.data.data;
	}

	/**
	 * Uploads a new inspection and adds part maps to it. returns id of new inspection.
	 * @summary Assemble Inspection 
	 */
	export async function postInspectionAssemble(req: CustomTypes.InspectionRequest) : Promise<number> {
		let res = await http.post<RedSky.RsResponseData<number>, CustomTypes.InspectionRequest>('/inspection/assemble', req);
		return res.data.data;
	}

	/**
	 * Get a paginated list of inspections
	 * @summary Inspection Paged 
	 */
	export async function getInspectionPaged(req: Api.V1.Inspection.Paged.Get.Req) : Promise<RedSky.RsPagedResponseData<Api.V1.Inspection.Paged.Get.Res[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.V1.Inspection.Paged.Get.Res[]>, Api.V1.Inspection.Paged.Get.Req>('/inspection/paged', req);
		return res.data;
	}

	/**
	 * Get the all inspection numbers that contain a partId
	 * @summary GET kitNumber by partId 
	 */
	export async function getInspectionByPartId(req: Api.V1.Inspection.By.PartId.Get.Req) : Promise<Api.V1.Inspection.By.PartId.Get.Res[]> {
		let res = await http.get<RedSky.RsResponseData<Api.V1.Inspection.By.PartId.Get.Res[]>, Api.V1.Inspection.By.PartId.Get.Req>('/inspection/by/partId', req);
		return res.data.data;
	}

}
