import * as React from 'react';
import { useEffect, useState } from 'react';
import './SettingsPage.scss';
import { Page } from '@redskytech/framework/996';
import PageHeader from '../../components/pageHeader/PageHeader';
import FirmwareList from '../../components/firmwareList/FirmwareList';
import { FirmwareSettings } from '../../components/firmwareList/FirmwareList';
import FirmwareEntry, { FirmwareFileEntryFields } from '../../components/firmwareList/firmwareEntry/FirmwareEntry';
import {
	Box,
	Button,
	Label,
	LabelInputText,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import useWarnOnUnsavedChanges from '../../customHooks/useWarnOnUnsavedChanges';
import serviceFactory from '../../services/serviceFactory';
import { TesterShimStatusData } from '../../services/socketio/SocketioService';
import { TestPrompt } from '../../services/testFixture/ITestFixtureService';
import PromptItem from '../../components/promptItem/PromptItem';
import ConfirmPopup, { ConfirmPopupProps } from '../../popups/confirmPopup/ConfirmPopup';

import TestCriteriaList from '../../components/testCriteriaList/TestCriteriaList';

import TestCriteriaReportPopup, {
	TestCriteriaReportPopupProps
} from '../../popups/testCriteriaReportPopup/TestCriteriaReportPopup';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SettingsPageProps {}

enum FormKeys {
	COMMERCIAL = 'commercial',
	MILITARY = 'military',
	RADIO = 'radio',
	VTO = 'vto',
	VTO_SERIAL = 'vtoSerial',
	BATTERY = 'battery',
	BATTERY_SERIAL = 'batterySerial',
	HOB = 'hob',
	HOB_SERIAL = 'hobSerial',
	ORIENT = 'orient',
	ORIENT_SERIAL = 'orientSerial'
}

export interface TestSettings {
	radioMhz: string;
	vtoId: string;
	vtoSerial: string;
	batteryId: string;
	batterySerial: string;
	hobId: string;
	hobSerial: string;
	orientId: string;
	orientSerial: string;
}

const SettingsPage: React.FC<SettingsPageProps> = (_props) => {
	const socketioService = serviceFactory.get('SocketioService');
	const [availableDiskSpace, setAvailableDiskSpace] = useState<string>('N/A GB');
	const [_isLoadingFirmwareInfo, setIsLoadingFirmwareInfo] = useState<boolean>(false);
	const [initialLoad, setInitialLoad] = useState<boolean>(true);
	const [testSettings, setTestSettings] = useRecoilState<TestSettings>(globalState.testSettings);
	const [testerShimStatus, _setTesterShimStatus] = useRecoilState<TesterShimStatusData>(globalState.testerShimStatus);
	const [downloadState, setDownloadState] = useRecoilState<'INPROGRESS' | 'DONE'>(globalState.firmwareDownloadState);
	const [activateState, setActivateState] = useRecoilState<'INPROGRESS' | 'DONE'>(globalState.firmwareActivateState);
	const [deleteState, setDeleteState] = useRecoilState<'INPROGRESS' | 'DONE'>(globalState.firmwareDeleteState);
	const [commercialOptions, setCommercialOptions] = useState<FirmwareFileEntryFields[]>([]);
	const [testPrompts, _setTestPrompts] = useRecoilState<TestPrompt[]>(globalState.testPrompts);
	const [militaryOptions, setMilitaryOptions] = useState<FirmwareFileEntryFields[]>([]);
	const [_firmwareSettings, setFirmwareSettings] = useRecoilState<FirmwareSettings>(globalState.firmwareSettings);

	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.RADIO, testSettings?.radioMhz || '', [
				new RsValidator(RsValidatorEnum.CUSTOM, 'Value must be between 1,000 and 6,000 MHz', (control) => {
					try {
						if (!control.value) return false;
						const mhz = parseInt(control.value.toString());
						return mhz > 1000 && mhz < 6000;
					} catch (e) {
						return false;
					}
				})
			]),
			new RsFormControl<string>(FormKeys.VTO, testSettings?.vtoId || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Value is required')
			]),
			new RsFormControl<string>(FormKeys.VTO_SERIAL, testSettings?.vtoSerial || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Value is required')
			]),
			new RsFormControl<string>(FormKeys.BATTERY, testSettings?.batteryId || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Value is required')
			]),
			new RsFormControl<string>(FormKeys.BATTERY_SERIAL, testSettings?.batterySerial || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Value is required')
			]),
			new RsFormControl<string>(FormKeys.HOB, testSettings?.hobId || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Value is required')
			]),
			new RsFormControl<string>(FormKeys.HOB_SERIAL, testSettings?.hobSerial || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Value is required')
			]),
			new RsFormControl<string>(FormKeys.ORIENT, testSettings?.orientId || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Value is required')
			]),
			new RsFormControl<string>(FormKeys.ORIENT_SERIAL, testSettings?.orientSerial || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Value is required')
			])
		])
	);

	useEffect(() => {
		if (initialLoad) {
			setInitialLoad(false);
		} else {
			if (downloadState === 'INPROGRESS') return;
			if (activateState === 'INPROGRESS') return;
			if (deleteState === 'INPROGRESS') return;
		}
		setIsLoadingFirmwareInfo(true);
		async function getFirmwareListInformation() {
			try {
				const commercial: FirmwareFileEntryFields[] = [];
				const military: FirmwareFileEntryFields[] = [];

				if (testerShimStatus.status === 'DISCONNECTED') {
					// Perhaps leave out this error, the page is already pretty obviously broken without it.
					rsToastify.error(
						'Tester shim is not connected, Cannot retrieve FW data.',
						'Tester Shim Not Connected'
					);
					return;
				}

				const shimresponsejson = await socketioService.getFwVersionData();
				// Todo, restrict the type in the service call, and do a JSON style check of the response for empty array.
				if (!ObjectUtils.isArrayWithData(shimresponsejson.versions)) {
					return;
				}
				let commercialFirmware = '';
				let militaryFirmware = '';
				shimresponsejson.versions.forEach((version: any) => {
					if (version.type.split('_')[0] === 'MILITARY') {
						military.push({
							version: version.version,
							name: version.name,
							url: version.url,
							isDownloaded: version.downloaded,
							isSelected: version.active,
							size: `${version.fileSize} GB`,
							isDownloading: false
						});
						if (version.active) militaryFirmware = version.version;
					} else {
						commercial.push({
							version: version.version,
							name: version.name,
							url: version.url,
							isDownloaded: version.downloaded,
							isSelected: version.active,
							size: `${version.fileSize} GB`,
							isDownloading: false
						});
						if (version.active) commercialFirmware = version.version;
					}
				});

				setFirmwareSettings({
					commercialFirmware: commercialFirmware,
					militaryFirmware: militaryFirmware
				});
				setAvailableDiskSpace(`${shimresponsejson.availableSpace} GB`);
				// update the variables here.
				setCommercialOptions(commercial);
				setMilitaryOptions(military);
			} catch (error) {
				rsToastify.error(WebUtils.getRsErrorMessage(error, 'Unknown Error'), 'Error Loading Firmware');
			}
			setIsLoadingFirmwareInfo(false);
		}
		getFirmwareListInformation().catch(console.error);
	}, [downloadState, activateState, deleteState, testerShimStatus.status]);

	useWarnOnUnsavedChanges(
		formGroup.isModified() ||
			downloadState === 'INPROGRESS' ||
			activateState === 'INPROGRESS' ||
			deleteState === 'INPROGRESS'
	);

	function handleSetActive(type: 'COMMERCIAL' | 'MILITARY', firmware: FirmwareFileEntryFields) {
		setActivateState('INPROGRESS');
		socketioService.activateFwVersionData(firmware.version);
	}

	function handleFirmwareDownload(type: 'COMMERCIAL' | 'MILITARY', firmware: FirmwareFileEntryFields) {
		if (downloadState === 'INPROGRESS') {
			rsToastify.warning('Download already in Progress, Please Wait', 'Only one Download at a time supported');
			return;
		}
		setDownloadState('INPROGRESS');
		firmware.isDownloading = true;
		socketioService.downloadFwVersionData(firmware.version);
	}

	function handleFirmwareDelete(type: 'COMMERCIAL' | 'MILITARY', firmware: FirmwareFileEntryFields) {
		popupController.open<ConfirmPopupProps>(ConfirmPopup, {
			title: 'Delete Firmware',
			message: 'Are you sure you want to delete this firmware?',
			confirmButtonText: 'Delete',
			closeButtonText: 'Cancel',
			onConfirm: () => {
				setDeleteState('INPROGRESS');
				socketioService.deleteFwVersionData(firmware.version);
			},
			onCancel: () => {}
		});
	}

	function handleDiscard() {
		setFormGroup(formGroup.cloneDeep().resetToInitialValue());
	}

	async function handleSave() {
		if (!(await formGroup.isValid())) {
			setFormGroup(formGroup.clone());
			rsToastify.error('Please fix the errors in the inputs.', 'Invalid Inputs');
			return;
		}
		setTestSettings({
			radioMhz: formGroup.get<string>(FormKeys.RADIO).value,
			vtoId: formGroup.get<string>(FormKeys.VTO).value,
			vtoSerial: formGroup.get<string>(FormKeys.VTO_SERIAL).value,
			batteryId: formGroup.get<string>(FormKeys.BATTERY).value,
			batterySerial: formGroup.get<string>(FormKeys.BATTERY_SERIAL).value,
			hobId: formGroup.get<string>(FormKeys.HOB).value,
			hobSerial: formGroup.get<string>(FormKeys.HOB_SERIAL).value,
			orientId: formGroup.get<string>(FormKeys.ORIENT).value,
			orientSerial: formGroup.get<string>(FormKeys.ORIENT_SERIAL).value
		});
		setFormGroup(formGroup.cloneDeep().updateInitialValues());
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function renderCommercialFirmware() {
		return commercialOptions.map((firmwareData, index) => {
			return (
				<FirmwareEntry
					key={firmwareData.version}
					isSelected={firmwareData.isSelected}
					isDownloaded={firmwareData.isDownloaded}
					version={firmwareData.name}
					size={firmwareData.size}
					isDownloading={firmwareData.isDownloading}
					isEven={index % 2 === 0}
					onSetActive={() => handleSetActive('COMMERCIAL', firmwareData)}
					onDownload={() => handleFirmwareDownload('COMMERCIAL', firmwareData)}
					onDelete={() => handleFirmwareDelete('COMMERCIAL', firmwareData)}
				/>
			);
		});
	}

	function renderMilitaryFirmware() {
		//translate the array of firmware data entries into a map of react firmware entry objects
		return militaryOptions.map((firmwareData, index) => {
			return (
				<FirmwareEntry
					key={firmwareData.version}
					isSelected={firmwareData.isSelected}
					isDownloaded={firmwareData.isDownloaded}
					version={firmwareData.name}
					size={firmwareData.size}
					isEven={index % 2 === 0}
					isDownloading={firmwareData.isDownloading}
					onSetActive={() => handleSetActive('MILITARY', firmwareData)}
					onDownload={() => handleFirmwareDownload('MILITARY', firmwareData)}
					onDelete={() => handleFirmwareDelete('MILITARY', firmwareData)}
				/>
			);
		});
	}

	function renderPrompts() {
		if (!ObjectUtils.isArrayWithData(testPrompts)) return;
		return [...testPrompts]
			.sort((a, b) => {
				return a.position - b.position;
			})
			.map((prompt) => {
				if (prompt.text !== '') {
					return <PromptItem disableAudio key={Math.random() * 1000} {...prompt} disabled={false} />;
				}
			});
	}

	return (
		<Page className={'rsSettingsPage'}>
			<PageHeader
				title={'Settings'}
				isModified={formGroup.isModified()}
				centerNode={formGroup.isModified() && 'Unsaved Changes'}
				rightNode={
					formGroup.isModified() && (
						<Box display={'flex'} gap={16}>
							<Button look={'outlinedPrimary'} onClick={handleDiscard}>
								Discard
							</Button>
							<Button look={'containedPrimary'} onClick={handleSave}>
								Save
							</Button>
						</Box>
					)
				}
			/>
			<Box className={'contentWrapper'}>
				<Box display={'flex'} flexDirection={'column'} gap={16}>
					<Box className={'firmwareHeader'} display={'flex'} gap={8}>
						<Label className={'firmwareTitle'} variant={'subheader1'} weight={'medium'}>
							Firmware
						</Label>
						<Label
							variant={'caption1'}
							weight={'regular'}
						>{`${availableDiskSpace} Disk Space Remaining`}</Label>
					</Box>
					{renderPrompts()}
					<Box>
						<Label variant={'body1'} weight={'regular'}>
							Commercial Firmware Options
						</Label>
						<FirmwareList testerStatus={testerShimStatus.status}>{renderCommercialFirmware()}</FirmwareList>
					</Box>
					<Box>
						<Label variant={'body1'} weight={'regular'}>
							Military Firmware Options
						</Label>
						<FirmwareList testerStatus={testerShimStatus.status}>{renderMilitaryFirmware()}</FirmwareList>
					</Box>
				</Box>
				<Box className={'dividerLine'} />
				<Label variant={'subheader1'} weight={'medium'}>
					Other
				</Label>
				<LabelInputText
					labelTitle={'Radio Frequency MHz'}
					inputMode={'text'}
					type={'text'}
					control={formGroup.get(FormKeys.RADIO)}
					updateControl={handleUpdateControl}
				/>
				<Box className={'dividerLine'} />
				<Label variant={'subheader1'} weight={'medium'}>
					Port Identifiers
				</Label>
				<Label variant={'caption1'} weight={'regular'}>
					Note: Not all ports are used by each test fixture
				</Label>
				<Box display={'flex'} gap={16}>
					<LabelInputText
						labelTitle={'VTO Port Manufacturer ID'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(FormKeys.VTO)}
						updateControl={handleUpdateControl}
					/>
					<LabelInputText
						labelTitle={'VTO Port Serial Number'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(FormKeys.VTO_SERIAL)}
						updateControl={handleUpdateControl}
					/>
				</Box>
				<Box display={'flex'} gap={16}>
					<LabelInputText
						labelTitle={'Battery Wake Port Manufacturer ID'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(FormKeys.BATTERY)}
						updateControl={handleUpdateControl}
					/>
					<LabelInputText
						labelTitle={'Battery Wake Port Serial Number'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(FormKeys.BATTERY_SERIAL)}
						updateControl={handleUpdateControl}
					/>
				</Box>
				<Box display={'flex'} gap={16}>
					<LabelInputText
						labelTitle={'HOB Port Manufacturer ID'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(FormKeys.HOB)}
						updateControl={handleUpdateControl}
					/>
					<LabelInputText
						labelTitle={'HOB Port Serial Number'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(FormKeys.HOB_SERIAL)}
						updateControl={handleUpdateControl}
					/>
				</Box>
				<Box display={'flex'} gap={16}>
					<LabelInputText
						labelTitle={'Orient Manufacturer ID'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(FormKeys.ORIENT)}
						updateControl={handleUpdateControl}
					/>
					<LabelInputText
						labelTitle={'Orient Serial Number'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(FormKeys.ORIENT_SERIAL)}
						updateControl={handleUpdateControl}
					/>
				</Box>
				<Box className={'dividerLine'} />
				<Label variant={'subheader1'} weight={'medium'} marginBottom={16}>
					Test Criteria Information
				</Label>

				<Box>
					<TestCriteriaList></TestCriteriaList>
				</Box>
				<Box marginTop={16}>
					<Button
						look="containedPrimary"
						onClick={() => {
							popupController.open<TestCriteriaReportPopupProps>(TestCriteriaReportPopup, {
								summaryData: []
							});
						}}
					>
						View/Generate Current Criteria
					</Button>
				</Box>
			</Box>
		</Page>
	);
};
export default SettingsPage;
