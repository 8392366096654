import { Box, Img, Label } from '@redskytech/framework/ui';
import * as React from 'react';
import { useMemo } from 'react';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import './TestCriteriaPdf.scss';
import { ITestSuite } from '../../utils/testCriteria';
import TestCriteriaTable from './testCriteriaTable/TestCriteriaTable';
import serviceFactory from '../../services/serviceFactory';

export interface TestCriteriaPdfProps {
	testSuites: ITestSuite[];
	userFirstName?: string;
	userLastName?: string;
	generatedDate?: string;
	shimVersion?: string;
	webVersion?: string;
	toolsVersion?: string;
}

const TestCriteriaPdf: React.FC<TestCriteriaPdfProps> = (props) => {
	const socketioService = serviceFactory.get('SocketioService');
	const currentVersionDetails = import.meta.env.PACKAGE_VERSION;
	const renderedTestSuites = useMemo(() => {
		return renderTestSuites();
	}, [props.testSuites]);

	function renderTestSuites() {
		const nodes = props.testSuites
			?.map((suite, index) => {
				return <TestCriteriaTable key={`CriteriaTable${suite.testSuiteName}${index}`} testSuite={suite} />;
			})
			.filter(Boolean);
		nodes?.push(<div style={{ 'break-after': 'page' }} />);
		return nodes;
	}

	return (
		<Box className="rsTestCriteriaPdf">
			<Box className="pdfHeader">
				<Box borderRight={'1px solid var(--Neutral-Grey-400, #CED4DA)'}>
					<Box p="0 8px 8px 0" borderBottom={'1px solid var(--Neutral-Grey-400, #CED4DA)'}>
						<Label variant={'h4'} weight={'semiBold'}>
							Test Requirement List
						</Label>
					</Box>
					<Box display="grid" gridTemplateColumns="1fr 1fr" p={'8px 0'}>
						<Box>
							<Label variant="caption2" weight="regular">
								Web Application Version
							</Label>
							<Label variant="caption1" weight="bold">
								{props.webVersion ? props.webVersion : currentVersionDetails}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Test Shim Versions
							</Label>
							<Label variant="caption1" weight="bold">
								Shim Version: {props.shimVersion ? props.shimVersion : socketioService.getShimVersion()}
							</Label>
							<Label variant="caption1" weight="bold">
								Tools Version:{'\n'}
								{props.toolsVersion ? props.toolsVersion : socketioService.getTestToolsVersion()}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Generated By
							</Label>
							<Label variant="caption1" weight="bold">
								{props.userFirstName ? props.userFirstName : 'Unknown'}{' '}
								{props.userLastName ? props.userLastName : 'Unknown'}
							</Label>
						</Box>
						<Box>
							<Label variant="caption2" weight="regular">
								Date
							</Label>
							<Label variant="caption1" weight="bold">
								{props.generatedDate
									? new Date(props.generatedDate).toDateString()
									: new Date().toDateString()}
							</Label>
						</Box>
					</Box>
				</Box>
				<Box pb={8}>
					<Img
						src={'/pdfStatic/logo_black_wordmark.png'}
						alt="FLIR Black Logo"
						width="136px"
						height="31px"
						mb={8}
					/>
					<Box>
						<Label variant="caption2" weight="regular">
							100 Midland Road
						</Label>
						<Label variant="caption2" weight="regular">
							Oak Ridge, TN 37830
						</Label>
						<Label variant="caption2" weight="regular">
							T +1.865.220.8700x101
						</Label>
						<Label variant="caption2" weight="regular">
							F +1.865.220.7181
						</Label>
					</Box>
				</Box>
			</Box>
			{!renderedTestSuites ? (
				<Box mt="16px" position="relative" height="250px">
					<LoadingSpinner />
				</Box>
			) : (
				<>{renderedTestSuites}</>
			)}
		</Box>
	);
};

export default TestCriteriaPdf;
