import { Kit, KitGroupType, InspectionGroupType, Inspection } from './IKitService';
import {
	partAssemblies,
	baseParts,
	payloadPartNumbers,
	vehiclePartNumbers,
	fcuPartNumbers,
	aurPartNumbers,
	chassisPartNumbers
} from '../assembly/assembly.data';

export const kitGroups: { [key in KitGroupType]: Kit } = {
	BATTERY: {
		label: 'Batteries',
		partNumbers: [...partAssemblies['BATTERY_ASSEMBLY'].partNumbers],
		checklist: [
			{ checklistKey: 'checkFirmwareVersion', label: 'Check firmware version', textArea: true },
			{
				checklistKey: 'vehicleConnection',
				label: 'Connect to a vehicle and power vehicle on and off using battery button',
				textArea: false
			},
			{ checklistKey: 'checkStateOfCharge', label: 'Check state of charge', textArea: false },
			{ checklistKey: 'visualInspection', label: 'Visual Inspection', textArea: false }
		]
	},
	CHARGER: {
		label: 'Chargers',
		partNumbers: [...partAssemblies['CHARGER_ASSEMBLY'].partNumbers],
		checklist: [
			{ checklistKey: 'checkFirmwareVersion', label: 'Check firmware version', textArea: true },
			{
				checklistKey: 'batteryConnection',
				label: 'Connect a battery and verify LED indicator blinks',
				textArea: false
			},
			{ checklistKey: 'visualInspection', label: 'Visual Inspection', textArea: false }
		]
	},
	FCU_ANTENNA: {
		label: 'FCU Antennas',
		partNumbers: [...fcuPartNumbers],
		checklist: [
			{ checklistKey: 'radioInP2P', label: 'Radio in P2P', textArea: false },
			{ checklistKey: 'visualInspection', label: 'Visual Inspection', textArea: false }
		]
	},
	PAYLOAD: {
		label: 'Payloads',
		partNumbers: [...payloadPartNumbers],
		checklist: [
			{ checklistKey: 'checkFirmwareVersion', label: 'Check firmware version', textArea: true },
			{ checklistKey: 'checkOTAVideo', label: 'Check video over air', textArea: false },
			{ checklistKey: 'checkOTAPointing', label: 'Check pointing over air', textArea: false },
			{ checklistKey: 'ctdSwitchedOff', label: 'CTD switched to off position', textArea: false },
			{ checklistKey: 'clearVideoAndLogFiles', label: 'Clear video and log files', textArea: false },
			{ checklistKey: 'visualInspection', label: 'Visual Inspection', textArea: false }
		]
	},
	TABLET: {
		label: 'Tablets',
		partNumbers: [...baseParts['TABLET'].partNumbers],
		checklist: [
			{ checklistKey: 'checkR1GCSVersion', label: 'Check R1GCS version', textArea: true },
			{ checklistKey: 'clearVideoAndLogFiles', label: 'Clear video and log files', textArea: false },
			{ checklistKey: 'visualInspection', label: 'Visual Inspection', textArea: false },
			{
				checklistKey: 'simulationModePurchased',
				label: 'Is Simulation Mode required for this tablet?',
				textArea: false,
				selectYesNo: true,
				linkedGroupKey: 'sim'
			},
			{
				checklistKey: 'simulationModeEnabled',
				label: 'Is Simulation Mode key installed?',
				textArea: false,
				selectYesNo: true,
				linkedGroupKey: 'sim'
			},
			{
				checklistKey: 'simulationModeTested',
				label: 'Ran Simulation Mode to verify functionality?',
				textArea: false,
				selectYesNo: true,
				linkedGroupKey: 'sim'
			}
		]
	},
	VEHICLE: {
		label: 'Vehicles',
		partNumbers: [...vehiclePartNumbers],
		checklist: [
			{ checklistKey: 'checkFirmwareVersion', label: 'Check firmware version', textArea: true },
			{ checklistKey: 'radioInP2P', label: 'Radio in P2P', textArea: false },
			{ checklistKey: 'checkOTAVideo', label: 'Check video over air', textArea: false },
			{ checklistKey: 'clearVideoAndLogFiles', label: 'Clear video and log files', textArea: false },
			{ checklistKey: 'visualInspection', label: 'Visual Inspection', textArea: false }
		]
	},
	AUR: {
		label: 'AURs',
		partNumbers: [...aurPartNumbers],
		checklist: [
			{ checklistKey: 'checkFirmwareVersion', label: 'Check firmware version', textArea: true },
			{ checklistKey: 'radioInP2P', label: 'Radio in P2P', textArea: false }
		]
	}
};

export const inspectionGroups: { [key in InspectionGroupType]: Inspection } = {
	BATTERY: {
		label: 'Batteries',
		partNumbers: [...partAssemblies['BATTERY_ASSEMBLY'].partNumbers],
		checklist: { BZN: [], OAK: [], SLC: [] }
	},
	CHARGER: {
		label: 'Chargers',
		partNumbers: [...partAssemblies['CHARGER_ASSEMBLY'].partNumbers],
		checklist: { BZN: [], OAK: [], SLC: [] }
	},
	FCU_ANTENNA: {
		label: 'FCU Antennas',
		partNumbers: [...fcuPartNumbers],
		checklist: { BZN: [], OAK: [], SLC: [] }
	},
	PAYLOAD: {
		label: 'Payloads',
		partNumbers: [...payloadPartNumbers],
		checklist: {
			BZN: [
				{ checklistKey: 'fastenersVerified', label: 'All screws present and secure', textArea: false },
				{ checklistKey: 'labelsVerified', label: 'All Labels present', textArea: false },
				{
					checklistKey: 'serialVisible',
					label: 'Temporary QR codes removed and SN easily visible',
					textArea: false
				},
				{ checklistKey: 'opticsClean', label: 'Optics clean', textArea: false },
				{ checklistKey: 'ctdOff', label: "CTD switch at 'OFF'", textArea: false },
				{
					checklistKey: 'dataPresent',
					label: 'All information is present, including balance data',
					textArea: false
				},
				{ checklistKey: 'plasticsInspected', label: 'No visible gaps between plastic', textArea: false }
			],
			OAK: [],
			SLC: []
		}
	},
	CHASSIS: {
		label: 'Chassis',
		partNumbers: [...chassisPartNumbers],
		checklist: { BZN: [], OAK: [], SLC: [] }
	},
	TABLET: {
		label: 'Tablets',
		partNumbers: [...baseParts['TABLET'].partNumbers],
		checklist: { BZN: [], OAK: [], SLC: [] }
	},
	VEHICLE: {
		label: 'Vehicles',
		partNumbers: [...vehiclePartNumbers],
		checklist: { BZN: [], OAK: [], SLC: [] }
	},
	AUR: {
		label: 'AURs',
		partNumbers: [...aurPartNumbers],
		checklist: { BZN: [], OAK: [], SLC: [] }
	}
};
