import { Page } from '@redskytech/framework/996';
import { Box, InputText, RsFormControl, RsFormGroup } from '@redskytech/framework/ui';
import { DateUtils } from '@redskytech/framework/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTableFilterMeta, DataTableFilterMetaData, DataTableOperatorFilterMetaData } from 'primereact/datatable';
import * as React from 'react';
import classNames from 'classnames';
import { useState } from 'react';
import ColumnHeader from '../../components/listDataTable/columnHeader/ColumnHeader';
import ListDataTable from '../../components/listDataTable/ListDataTable';
import TableSearchHeader from '../../components/listDataTable/tableSearchHeader/TableSearchHeader';
import PageHeader from '../../components/pageHeader/PageHeader';
import useDataTableFilters from '../../customHooks/useDataTableFilters';
import { ApiRequestV1 } from '../../generated/apiRequests';
import router from '../../utils/router';
import './IntersiteListPage.scss';
import useIsMounted from '../../customHooks/useIsMounted';

const FieldKeys = {
	INSPECTION_NUMBER: 'inspection.id',
	SITE: 'inspection.site',
	CREATED_ON: 'inspection.createdOn',
	CREATED_FIRST_NAME: 'createdById_user.firstName',
	CREATED_LAST_NAME: 'createdById_user.lastName'
};

const IntersiteListPage: React.FC = () => {
	const [inspectionRecords, setInspectionRecords] = useState<
		RedSky.RsPagedResponseData<Api.V1.Inspection.Paged.Get.Res[]>
	>({
		data: [],
		total: 0
	});

	const [activeSortColumn, setActiveSortColumn] = useState<string>();

	const isMounted = useIsMounted();
	const queryParams = router.getQueryParams<{
		sortField: string;
		sortOrder: string;
		globalSearch: string;
		page: number;
		perPage: number;
		filter: string;
	}>([
		{
			key: 'sortField',
			default: FieldKeys.CREATED_ON,
			type: 'string'
		},
		{
			key: 'sortOrder',
			default: 'DESC',
			type: 'string'
		},
		{
			key: 'page',
			default: 1,
			type: 'integer'
		},
		{
			key: 'perPage',
			default: 25,
			type: 'integer'
		},
		{
			key: 'filter',
			default: '',
			type: 'string'
		}
	]);

	const initialFiltersFormGroup: RsFormGroup = new RsFormGroup([
		new RsFormControl<string>(
			FieldKeys.INSPECTION_NUMBER,
			getQueryParamValueForFilter(FieldKeys.INSPECTION_NUMBER) || ''
		),
		new RsFormControl<string>(FieldKeys.CREATED_ON, getQueryParamValueForFilter(FieldKeys.CREATED_ON) || ''),
		new RsFormControl<string>(FieldKeys.SITE, getQueryParamValueForFilter(FieldKeys.SITE) || ''),
		new RsFormControl<string>(
			FieldKeys.CREATED_FIRST_NAME,
			getQueryParamValueForFilter(FieldKeys.CREATED_FIRST_NAME) || ''
		)
	]);
	const initialFilters: DataTableFilterMeta = {
		[FieldKeys.INSPECTION_NUMBER]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.INSPECTION_NUMBER), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.CREATED_ON]: {
			operator: FilterOperator.AND,
			constraints: [
				{ value: getQueryParamValueForFilter(FieldKeys.CREATED_ON), matchMode: FilterMatchMode.CONTAINS }
			]
		},
		[FieldKeys.SITE]: {
			operator: FilterOperator.AND,
			constraints: [{ value: getQueryParamValueForFilter(FieldKeys.SITE), matchMode: FilterMatchMode.CONTAINS }]
		},
		[FieldKeys.CREATED_FIRST_NAME]: {
			operator: FilterOperator.AND,
			constraints: [
				{
					value: getQueryParamValueForFilter(FieldKeys.CREATED_FIRST_NAME),
					matchMode: FilterMatchMode.CONTAINS
				}
			]
		}
	};

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		filters,
		globalSearch,
		setGlobalSearch,
		activeFilters,
		filtersFormGroup,
		handleUpdateControl,
		handleClearAllFilters,
		handleFilterClear,
		handleFilterApply
	} = useDataTableFilters(initialFilters, initialFiltersFormGroup, getUpdatedFilters, queryParams.globalSearch);

	async function getData(pageQuery: RedSky.PageQuery) {
		setIsLoading(true);
		setActiveSortColumn(pageQuery.sortBy);
		const filterValues: string[] = [];
		for (const i in filters) {
			if (Object.prototype.hasOwnProperty.call(filters[i], 'constraints')) {
				(filters[i] as DataTableOperatorFilterMetaData).constraints.forEach(
					(constraint: DataTableFilterMetaData) => {
						if (constraint.value !== null) filterValues.push(`${i}_${constraint.value}`);
					}
				);
			}
		}

		router.updateQueryParams({
			sortField: pageQuery.sortBy || '',
			sortOrder: pageQuery.sortOrder || -1,
			globalSearch,
			page: pageQuery.page || 1,
			perPage: pageQuery.perPage || 25,
			...(filterValues.length && { filter: filterValues.join(',') })
		});

		try {
			if (!isMounted) return;
			if (pageQuery.sortBy === undefined) {
				delete pageQuery.sortBy;
				delete pageQuery.sortOrder;
			}
			if (!pageQuery.filter) delete pageQuery.filter;
			if (pageQuery.page === undefined || pageQuery.perPage === undefined) return;
			const res = await ApiRequestV1.getInspectionPaged({
				page: pageQuery.page || 1,
				perPage: pageQuery.perPage || 50,
				...pageQuery
			});
			setInspectionRecords(res);
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	}

	function handleRowClick(rowData: any) {
		router.navigate(`/inspection/checklist?in=${rowData.data.id}`);
	}

	function getQueryParamValueForFilter(filterName: string): string | null {
		// Quick check to see if it's in here
		if (!queryParams.filter.includes(filterName)) return null;
		const filters = queryParams.filter.split(',');
		const filter = filters.find((filter) => filter.includes(filterName));
		if (!filter) return null;
		const filterSplit = filter.split('_');
		if (filterSplit.length !== 2) return null;
		return filterSplit[1];
	}

	function getUpdatedFilters(field: string, value: any) {
		const filter: any = filters[field];

		const constraint = [
			{
				value: value,
				matchMode: filter?.constraints[0]?.matchMode
			}
		];
		if (field === FieldKeys.CREATED_FIRST_NAME) {
			return {
				...filters,
				[field]: {
					...filter,
					constraints: constraint
				},
				[FieldKeys.CREATED_LAST_NAME]: {
					operator: FilterOperator.OR,
					constraints: constraint
				}
			};
		}
		return {
			...filters,
			[field]: {
				...filter,
				constraints: constraint
			}
		};
	}

	return (
		<Page className={'rsIntersiteListPage'}>
			<PageHeader title={'Inspection Records'} />
			<Box className={'pageContent'}>
				<ListDataTable
					loading={isLoading}
					onClearAllFilters={handleClearAllFilters}
					onFilterClear={handleFilterClear}
					onFilterApply={handleFilterApply}
					onRowClick={handleRowClick}
					data={inspectionRecords}
					getData={getData}
					globalFilter={globalSearch}
					globalFilterFields={[
						FieldKeys.INSPECTION_NUMBER,
						FieldKeys.SITE,
						FieldKeys.CREATED_FIRST_NAME,
						FieldKeys.CREATED_LAST_NAME
					]}
					sortField={queryParams.sortField}
					sortOrder={queryParams.sortOrder === 'ASC' ? 1 : -1}
					filters={filters}
					rowsPerPageOptions={[10, 25, 50, 100]}
					initialRowsPerPage={queryParams.perPage}
					first={(queryParams.page - 1) * queryParams.perPage}
					header={
						<TableSearchHeader
							searchValue={globalSearch}
							onChange={(value: string) => setGlobalSearch(value)}
							placeholder={'Search Inspections'}
							title={''}
						/>
					}
				>
					<Column
						field={FieldKeys.INSPECTION_NUMBER}
						sortField={FieldKeys.INSPECTION_NUMBER}
						header={<ColumnHeader label={'Inspection #'} />}
						body={(part: Api.V1.Inspection.Paged.Get.Res) => part.id}
						sortable
					/>
					<Column
						field={FieldKeys.SITE}
						sortField={FieldKeys.SITE}
						header={<ColumnHeader label={'Inspection Site'} />}
						body={(part: Api.V1.Inspection.Paged.Get.Res) => part.site}
						sortable
						filter
						filterElement={
							<InputText
								inputMode={'text'}
								placeholder={'Site'}
								control={filtersFormGroup.get(FieldKeys.SITE)}
								updateControl={handleUpdateControl}
							/>
						}
					/>
					<Column
						field={FieldKeys.CREATED_ON}
						sortField={FieldKeys.CREATED_ON}
						header={<ColumnHeader label={'Created On'} />}
						body={(part: Api.V1.Inspection.Paged.Get.Res) => DateUtils.displayDate(part.createdOn)}
						sortable
					/>
					<Column
						className={classNames({ activeFilter: activeFilters.includes(FieldKeys.CREATED_FIRST_NAME) })}
						field={FieldKeys.CREATED_FIRST_NAME}
						sortField={FieldKeys.CREATED_FIRST_NAME}
						header={
							<ColumnHeader
								label={'Created By'}
								isActiveSort={activeSortColumn === FieldKeys.CREATED_FIRST_NAME}
							/>
						}
						sortable
						body={(criteria: Api.V1.Inspection.Paged.Get.Res) =>
							`${criteria.createdByFirstName} ${criteria.createdByLastName}`
						}
					/>
				</ListDataTable>
			</Box>
		</Page>
	);
};

export default IntersiteListPage;
