import { Box, Button, Label, Paper, Popup, PopupProps, rsToastify } from '@redskytech/framework/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { ObjectUtils } from '../../utils/utils';
import { HardwareIdDecoded } from '../../services/assembly/AssemblyService';
import { KitGroupType } from '../../services/kit/IKitService';
import { generateCriteriaPdf } from '../../utils/pdfUtil';
import './TestCriteriaReportPopup.scss';
import { ITestSuite } from '../../utils/testCriteria';
import { Fixture, PythonTest, TestResult } from '../../services/testFixture/ITestFixtureService';
import { generateTestSuitesList } from '../../utils/testRequirementGenerator';
import TestCriteriaPdf from '../../components/testCriteriaPdf/TestCriteriaPdf';
import globalState from '../../state/globalState';
import { useRecoilState, useRecoilValue } from 'recoil';

import serviceFactory from '../../services/serviceFactory';
import { ApiRequestV1 } from '../../generated/apiRequests';

export interface TestCriteriaReportPopupProps extends PopupProps {
	summaryData: { group: KitGroupType; idList: HardwareIdDecoded[] }[];
	sourceTestSuite?: { webVersion: string; shimVersion: string; toolsVersion: string };
}

const TestCriteriaReportPopup: React.FC<TestCriteriaReportPopupProps> = (props) => {
	const testerShimStatus = useRecoilValue(globalState.testerShimStatus);
	const socketioService = serviceFactory.get('SocketioService');
	const [testSuiteInfo, setTestSuiteInfo] = useState<ITestSuite[]>([]);
	const [isRunning, setIsRunning] = useState(false);
	const [testResults, setTestResults] = useRecoilState<TestResult[]>(globalState.testResults);
	const [currentTest, setCurrentTest] = useState<PythonTest>();
	const [currentRunningIndex, setCurrentRunningIndex] = useState<number[]>([-1, -1]);
	const [testArray, setTestArray] = useState<Fixture[]>([]);
	const [collectionComplete, setCollectionComplete] = useState(false);
	const [fixtureIndexes, setFixtureIndexes] = useState<number[]>([]);
	const currentVersionDetails = import.meta.env.PACKAGE_VERSION;
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const [userFirstName, setUserFirstName] = useState(user?.firstName);
	const [userLastName, setUserLastName] = useState(user?.lastName);
	const [generatedDate, setGeneratedDate] = useState(new Date().toLocaleDateString());
	const [versionInfo, setVersionInfo] = useState<{ shimVersion: string; toolsVersion: string; webVersion: string }>({
		shimVersion: socketioService.getShimVersion(),
		toolsVersion: socketioService.getTestToolsVersion(),
		webVersion: currentVersionDetails
	});

	useEffect(() => {
		setTestResults([]);
		async function genTestSuites() {
			try {
				if (props.sourceTestSuite) {
					console.log('Attempting to retrieve props.sourceTestSuite');
					const sourceTestSuiteInfo = await ApiRequestV1.getDefinitionTest({
						webVersion: props.sourceTestSuite.webVersion,
						shimVersion: props.sourceTestSuite.shimVersion,
						toolsVersion: props.sourceTestSuite.toolsVersion
					});
					if (sourceTestSuiteInfo) {
						setVersionInfo({
							webVersion: props.sourceTestSuite.webVersion,
							shimVersion: props.sourceTestSuite.shimVersion,
							toolsVersion: props.sourceTestSuite.toolsVersion
						});
						setUserFirstName(sourceTestSuiteInfo.userFirstName);
						setUserLastName(sourceTestSuiteInfo.userLastName);
						setGeneratedDate(sourceTestSuiteInfo.createdOn);
						setCollectionComplete(true);
						setTestSuiteInfo(sourceTestSuiteInfo.definition as ITestSuite[]);
						console.log('successfully retrieced props.sourceTestSuite');
						console.log(props.sourceTestSuite);
					}
				} else {
					// This case uses the currently attached shim info the criteria that matches it.
					if (testerShimStatus.status !== 'CONNECTED_DATA') {
						rsToastify.info(
							`Shim not connected, cannot attempt to generate a new report.`,
							`Test Criteria Report`
						);
						throw new Error('Shim not connected');
					}

					// Try to get a current one, if it has already been generated this will throw and we will attempt to make one
					const sourceTestSuiteInfo = await ApiRequestV1.getDefinitionTest({
						webVersion: currentVersionDetails,
						shimVersion: socketioService.getShimVersion(),
						toolsVersion: socketioService.getTestToolsVersion()
					});
					if (sourceTestSuiteInfo) {
						setUserFirstName(sourceTestSuiteInfo.userFirstName);
						setUserLastName(sourceTestSuiteInfo.userLastName);
						setGeneratedDate(sourceTestSuiteInfo.createdOn);
						setCollectionComplete(true);
						setTestSuiteInfo(sourceTestSuiteInfo.definition as ITestSuite[]);
					}
				}
			} catch (err) {
				console.log(`Issue retrieving test definition, attempting to generate one. ${err}`);
				rsToastify.info(
					`Current Version Test Criteria Report requires generation, Attempting to gather info.`,
					`Test Criteria Report Generation`
				);
				const testList = await generateTestSuitesList();
				setTestSuiteInfo(testList);
			}
		}
		genTestSuites();
	}, []);

	useEffect(() => {
		// set next running index if we got a result
		if (!ObjectUtils.isArrayWithData(testResults) || isRunning === false) return;
		if (testSuiteInfo.length === 0 || currentTest === undefined) return;
		const result = testResults.find((res) => res.testName.split('_')[0] === currentTest.testName);
		if (!result) return;

		// if we have a result, increment the current index
		// and store the resulting criteria in the correct object!
		const newTestSuiteInfo = [...testSuiteInfo];
		const suiteInfoIndexMap = fixtureIndexes[currentRunningIndex[0]];
		if (
			suiteInfoIndexMap >= 0 &&
			currentRunningIndex[1] >= 0 &&
			suiteInfoIndexMap < newTestSuiteInfo.length &&
			currentRunningIndex[1] < newTestSuiteInfo[suiteInfoIndexMap].tests.length
		) {
			if (result.testCriteria) {
				newTestSuiteInfo[suiteInfoIndexMap].tests[currentRunningIndex[1]].testCriteria.push(
					...result.testCriteria
				);
			}
		} else {
			rsToastify.error(`Issue storing test criteria against test suite`, `Test Definition Generation Fault`);
		}

		if (result.testCriteria) {
			setTestSuiteInfo(newTestSuiteInfo);
		}
		setCurrentRunningIndex((prev) => {
			const nextFixtureIndex = prev[0] + 1;
			const nextTestIndex = prev[1] + 1;

			// handle the last step
			if (nextTestIndex >= testArray[prev[0]].tests.length) {
				//check to see if we can increment the fixture index safely
				if (nextFixtureIndex >= testArray.length) {
					setCollectionComplete(true);
					// when auto generating we can only do this if the shim is attached!
					ApiRequestV1.postDefinitionTest({
						definition: JSON.stringify(newTestSuiteInfo),
						webVersion: currentVersionDetails,
						shimVersion: socketioService.getShimVersion(),
						toolsVersion: socketioService.getTestToolsVersion()
					})
						.then(() => {
							rsToastify.success(
								`Test criteria collected and report generation complete`,
								`Test Criteria Report Generation`
							);
						})
						.catch((err) => {
							rsToastify.error(`${err}`, `Unable to upload Test Definition`);
						});

					// Set invalid indexes to prevent further processing
					return [-1, -1];
				}
				// Clear the test results because we are moving to the next fixture
				setTestResults([]);
				return [nextFixtureIndex, 0];
			}
			return [prev[0], nextTestIndex];
		});
	}, [testResults]);

	useEffect(() => {
		if (testArray.length !== 0) return;
		// generate a flat array of tests to process in order from the testSuiteInfo
		const localTestArray: Fixture[] = [];
		const fixtureIndexes: number[] = [];
		testSuiteInfo.forEach((suite, index) => {
			if (suite.fixtureInfo) {
				fixtureIndexes.push(index);
				localTestArray.push(suite.fixtureInfo);
			}
		});
		setFixtureIndexes(fixtureIndexes);
		setTestArray(localTestArray);
	}, [testSuiteInfo]);

	useEffect(() => {
		if (testArray.length === 0) return;
		setIsRunning(true);
		setCurrentRunningIndex([0, 0]);
	}, [testArray]);

	useEffect(() => {
		if (currentRunningIndex[0] < 0 || currentRunningIndex[1] < 0) return;
		if (testArray.length === 0) return;
		if (collectionComplete) return;
		if (testerShimStatus.status !== 'CONNECTED_DATA') return;

		triggerTest(currentRunningIndex[0], currentRunningIndex[1]);
	}, [currentRunningIndex]);

	async function triggerTest(fixtureIndex: number, testIndex: number) {
		// set selected step

		const currentTest = testArray[fixtureIndex].tests[testIndex];

		const args = [];
		args.push(...currentTest.pythonArgs);
		args.push('-s');
		setCurrentTest(currentTest);
		// get the test from the test list
		// We just use fixed args that provide exits from all of the tests
		args.push('-p1');
		args.push('4248661');
		args.push('-p2');
		args.push('00');
		args.push('-p3');
		args.push('00');
		args.push('-p4');
		args.push('12345');
		socketioService.runPythonScript(testArray[fixtureIndex].scriptName, ...args);
	}

	return (
		<Popup {...props}>
			<Paper className="rsTestCriteriaReportPopup">
				<Box display="flex" justifyContent="space-between" borderBottom={'1px solid black'}>
					<Label variant="h1" weight="bold" marginRight={'16px'}>
						Test Criteria Report
					</Label>
					<Button
						look="containedPrimary"
						onClick={() => {
							generateCriteriaPdf(
								document.querySelector('.rsTestCriteriaPdf') as HTMLElement,
								'testCriteria',
								[]
							);
						}}
					>
						Download PDF
					</Button>
				</Box>
				{collectionComplete && (
					<TestCriteriaPdf
						testSuites={testSuiteInfo}
						userFirstName={userFirstName}
						userLastName={userLastName}
						generatedDate={generatedDate}
						shimVersion={versionInfo.shimVersion}
						toolsVersion={versionInfo.toolsVersion}
						webVersion={versionInfo.webVersion}
					/>
				)}
			</Paper>
		</Popup>
	);
};

export default TestCriteriaReportPopup;
