import * as React from 'react';
import './ListPopup.scss';
import { Box, Label, Popup, popupController, PopupProps } from '@redskytech/framework/ui';
import PopupHeader from '../../components/popupHeader/PopupHeader';
import colors from '../../themes/colors.scss?export';
import router from '../../utils/router';

export interface ListPopupProps extends PopupProps {
	title: string;
	artifacts: { data: { fileUrl: string; label?: string }[] };
}

const ListPopup: React.FC<ListPopupProps> = (props) => {
	function renderArtifacts() {
		return props.artifacts.data.map((artifact, index) => {
			const fileName = artifact.label || artifact.fileUrl.split('/').pop();
			if (!fileName) return <Box></Box>;
			return (
				<Box>
					<Label
						className={'rsListLink'}
						key={`listLinkLabel${index}`}
						variant={'body1'}
						weight={'regular'}
						color={colors.primaryBlue700}
						underline
						onClick={() => {
							popupController.closeAll();
							router.navigate(artifact.fileUrl).catch(console.error);
						}}
					>
						{fileName}
					</Label>
				</Box>
			);
		});
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Box className={'rsListPopup'}>
				<PopupHeader
					title={`${props.title}`}
					onClose={() => {
						popupController.close(ListPopup);
					}}
				/>
				<Box className={'listContainer'}>{renderArtifacts()}</Box>
			</Box>
		</Popup>
	);
};
export default ListPopup;
