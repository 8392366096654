import NotFoundPage from './pages/notFoundPage/notFoundPage';
import LoginPage from './pages/loginPage/LoginPage';
import { I996 } from '@redskytech/framework/common/Interfaces';
import UserListPage from './pages/userListPage/UserListPage';
import UserDetailsPage from './pages/userDetailsPage/UserDetailsPage';
import AssemblyRecordsPage from './pages/assemblyRecordsPage/AssemblyRecordsPage';
import AssemblyDetailsPage from './pages/assemblyDetailsPage/AssemblyDetailsPage';
import AssemblePage from './pages/assemblePage/AssemblePage';
import TestDetailsPage from './pages/testDetailsPage/TestDetailsPage';
import BoardCalibrationPage from './pages/boardCalibrationPage/BoardCalibrationPage';
import VideoTestPage from './pages/videoTestPage/VideoTestPage';
import SettingsPage from './pages/settingsPage/SettingsPage';
import FlightTestPage from './pages/flightTestPage/FlightTestPage';
import LabelPage from './pages/labelPage/LabelPage';
import KitCheckoutPage from './pages/kitCheckoutPage/KitCheckoutPage';
import MagTestPage from './pages/magTestPage/MagTestPage.js';
import TabletSetupPage from './pages/tabletSetupPage/TabletSetupPage';
import ReportPage from './pages/reportPage/ReportPage';
import KitListPage from './pages/kitListPage/KitListPage';
import IntersiteChecklistPage from './pages/intersiteChecklistPage/IntersiteChecklistPage';
import IntersiteListPage from './pages/intersiteListPage/IntersiteListPage';

export type RoutePaths =
	| '/'
	| '/user/list'
	| '/user/details'
	| '*'
	| '/test'
	| '/label'
	| '/assembly/list'
	| '/assembly/details'
	| '/assemble'
	| '/kit/checkout'
	| '/kit/list'
	| '/calibration'
	| '/video'
	| '/inspection/checklist'
	| '/inspection/list'
	| '/settings'
	| '/flight/test'
	| '/mag-test'
	| '/tablet-setup'
	| '/report';

const routes: I996.RouteDetails<RoutePaths>[] = [
	{
		path: '/',
		page: LoginPage,
		options: {
			view: 'login'
		}
	},
	{
		path: '/user/list',
		page: UserListPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/user/details',
		page: UserDetailsPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/assembly/list',
		page: AssemblyRecordsPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/assembly/details',
		page: AssemblyDetailsPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/test',
		page: TestDetailsPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/assemble',
		page: AssemblePage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/calibration',
		page: BoardCalibrationPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/settings',
		page: SettingsPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/flight/test',
		page: FlightTestPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/kit/checkout',
		page: KitCheckoutPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/kit/list',
		page: KitListPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/inspection/checklist',
		page: IntersiteChecklistPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/inspection/list',
		page: IntersiteListPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/video',
		page: VideoTestPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/label',
		page: LabelPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/mag-test',
		page: MagTestPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/report',
		page: ReportPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '/tablet-setup',
		page: TabletSetupPage,
		options: {
			view: 'admin'
		}
	},
	{
		path: '*',
		page: NotFoundPage
	}
];

export default routes;
(window as any).routes = routes;
