import * as React from 'react';
import { useState } from 'react';
import './IntersiteChecklistSection.scss';
import { Box, Label, LabelSelect, RsFormControl } from '@redskytech/framework/ui';
import useIsMounted from '../../../customHooks/useIsMounted';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

interface IntersiteChecklistSectionProps {
	onSiteSelected: (SiteKey: string) => void;
	locationOptionInfo: { label: string; value: string }[];
	labelText?: string;
	pageTitle?: string;
	clearAfterEnter?: boolean;
	rightNode?: React.ReactNode;
	disabled?: boolean;
}

const IntersiteChecklistSection: React.FC<IntersiteChecklistSectionProps> = (props) => {
	const [locationControl, setLocationControl] = useState<RsFormControl<IRsFormControl>>(
		new RsFormControl<string>('location', '')
	);
	const isMounted = useIsMounted();
	const [_kitNumber, _setKitNumber] = useState<string>('');

	const [locationOptions, setLocationOptions] = useState<{ label: string; value: string }[]>(
		props.locationOptionInfo
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		if (isMounted) {
			setLocationControl(control);
			props.onSiteSelected(control.value ? (control.value as string) : 'UNK');
		}
	}

	return (
		<Box
			className={'rsIntersiteChecklistSection'}
			display={'flex'}
			gap={32}
			marginTop={16}
			flexDirection={'column'}
		>
			<Label variant={'subheader1'} weight={'regular'}>
				{props.labelText ? props.labelText : '1. Select your site'}
			</Label>

			<LabelSelect<{ label: string; value: string }>
				isCreatable
				className={'locationSelect'}
				labelTitle={''}
				placeholder={'Select'}
				options={locationOptions}
				onCreateOption={(value) => {
					setLocationOptions((prevState) => {
						return [
							...prevState,
							{
								label: value,
								value: value
							}
						];
					});
				}}
				isDisabled={props.disabled === undefined ? false : props.disabled}
				control={locationControl}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default IntersiteChecklistSection;
